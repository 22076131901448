import API, { graphqlOperation } from '@aws-amplify/api'
import error from './error'

export const ACTIONS = {
  LOAD: 'assets-load',
  LOADING: 'assets-loading',
  FILTER: 'assets-filter',
  SEARCH_ALL: 'assets-search-all'
}

const getAssetsQuery = `
  query ($workspacePath: String, $rfid: String) {
    getAssets(workspacePath: $workspacePath, rfid: $rfid) {
      rfid,
      companyId,
      workspacePath,
      isPresent,
      lastSeen
    }
  }
`

const actions = {
  filter: (rfid) => {
    return (dispatch) => {
      dispatch({ type: ACTIONS.FILTER, payload: rfid })
      dispatch(actions.getAssets())
    }
  },
  searchAll: (all) => {
    return (dispatch) => {
      dispatch({ type: ACTIONS.SEARCH_ALL, payload: all })
      dispatch(actions.getAssets())
    }
  },
  getAssets: () => {
    return (dispatch, getState) => {
      const { assets, workspace } = getState()
      const { building, floor, area } = workspace
      const { filter, searchAll } = assets

      const workspacePath = `/${building}/${floor}/${area}`
      dispatch({ type: ACTIONS.LOADING, payload: true })

      return API.graphql(graphqlOperation(getAssetsQuery, { workspacePath: searchAll ? null : workspacePath, rfid: filter}))
        .then((result) => dispatch({ type: ACTIONS.LOAD, payload: result.data.getAssets }))
        .catch((err) => dispatch(error.exception(err)))
        .then(() => dispatch({ type: ACTIONS.LOADING, payload: false }))
    }
  }
}

export default actions

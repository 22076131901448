import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import * as Constants from './../../application/constants'
import { STATUS } from '@ergosense/ergosense-react-common/lib/actions/notify'

const useStyles = makeStyles((theme) => ({
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  notification: {
    maxWidth: 350,
    position: 'relative',
    '&:hover $progress': {
      animationPlayState: 'paused'
    }
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 5,
    width: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    animation: '$progress 5000ms 1 linear'
  },
  '@keyframes progress': {
    from: {
      width: '100%'
    },
    to: {
      width: '0%'
    }
  },
}))

/**
 * Check if notification is error
 *
 * @param {int} type - The notification type
 *
 * @return {boolean}
 */
const isError = (type) => {
  return (type === Constants.STATUS.ERROR || type === STATUS.ERROR) || false
}

/**
 * Keeps track of a certain instance of a notification
 * and closes on animation end
 */
const NotificationMessage = ({ message, type, onClose }) => {
  const classes = useStyles()
  const error = isError(type)

  return (
    <Box display="flex" alignItems="center">
      {error ? <ErrorIcon className={clsx([classes.icon, classes.error])}/> : <InfoIcon className={classes.icon}/>}
      {message}
      <Box className={classes.progress} onAnimationEnd={onClose} />
    </Box>
  )
}

NotificationMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([Constants.STATUS.OK, Constants.STATUS.ERROR, STATUS.OK, STATUS.ERROR]),
  onClose: PropTypes.func.isRequired
}

/**
 * Snackbar notifications. Displays info/error notifications
 * based on application behaviour
 */
const Notification = ({ message, type, anchor }) => {
  const classes = useStyles()
  const [ open, setOpen ] = useState(false)

  // Close existing message
  const onClose = () => setOpen(false)

  // Open upon new notification
  useEffect(() => {
    setOpen(true)
  }, [message, type])

  const error = isError(type)

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={message && open}
        onClose={onClose}
        message={message ? <NotificationMessage message={message} type={type} onClose={onClose} /> : null}
        ContentProps={{ className: clsx({ [classes.notification]: true, [classes.error]: error }) }} />
    </React.Fragment>
  )
}

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf([Constants.STATUS.OK, Constants.STATUS.ERROR, STATUS.OK, STATUS.ERROR])
}

export default Notification

export const Connected = (props) => {
  const { message, type} = useSelector((state) => state.notification.notification) || {}

  props = {
    ...props,
    message,
    type
  }

  return <Notification {...props} />
}
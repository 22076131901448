import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import * as queries from './../gql/queries'
import * as mutations from './../gql/mutations'
import notification from './notification'

// Action Constants
export const ACTION = {
  LOAD_KEYS: 'api-load-keys',
  SET_WEBHOOK: 'set-webhook-url',
  GET_WEBHOOK: 'get-webhook-url',
  GENERATE_TOKEN: 'generate-api-token',
  CLEAR_GENERATED: 'clear-generated-api-token',
  GET_LINEDATA: 'get-line-data',
  SET_LINEDATA: 'set-line-data',
}


export const LOAD = ACTION

// Mutation for creating API access key
const createApiAccessKeyMutation = `
  mutation {
    createApiAccessKey {
      prefix
      generatedToken
    }
  }
`

// Action to get API access keys
export const getApiAccessKeys = () => {
  return loader.load(LOAD.LOAD_KEYS, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getApiAccessKeys))
      .then((result) => dispatch({ type: ACTION.LOAD_KEYS, data: result.data.getApiAccessKeys }))
  })
}

// Clear generated token action
export const clearGenerated = () => ({ type: ACTION.CLEAR_GENERATED })

// Action to create API access key and refresh access keys list
export const createApiAccessKey = () => {
  return loader.load(LOAD.GENERATE_TOKEN, (dispatch) => {
    return API.graphql(graphqlOperation(createApiAccessKeyMutation))
      .then((result) => dispatch({ type: ACTION.GENERATE_TOKEN, data: result.data.createApiAccessKey }))
      .then(() => dispatch(getApiAccessKeys()))
  })
}

// Set webhook URL action
export const setWebhook = (url, enabled) => {
  return loader.load(LOAD.SET_WEBHOOK, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.setWebhook, { url, enabled }))
      .then((result) => dispatch({ type: ACTION.SET_WEBHOOK, data: result.data.setWebhook }))
      .then(() => dispatch(notification.success('Webhook successfully updated')))
  })
}

// Get webhook URL action
export const getWebhook = () => {
  return loader.load(LOAD.GET_WEBHOOK, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getWebhook))
      .then((result) => dispatch({ type: ACTION.GET_WEBHOOK, data: result.data.getWebhook }))
  })
}


// Set lineData action
export const setLineData = (position, color, isVisible) => {
  return loader.load(LOAD.SET_LINEDATA, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.setLineData, { position, color, isVisible }))
      .then((result) => dispatch({ type: ACTION.SET_LINEDATA, data: result.data.setLineData }))
      .then(() => dispatch(notification.success('Line data successfully updated')))
  })
}


// Get lineData action
export const getLineData = () => {
  return loader.load(LOAD.GET_LINEDATA, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getLineData))
      .then((result) => dispatch({ type: ACTION.GET_LINEDATA, data: result.data.getLineData }))
  })
}

// Store line name in redux
export const setLineNameInRedux = (lineName) => ({
  type: 'SET_LINE_NAME',
  payload: lineName,
});

//set line name from modal in redux
const lineDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LINE_NAME':
      return {
        ...state,
        lineName: action.payload,  // Store only the lineName
      };
    default:
      return state;
  }
};



// Initial state
const initial = {
  data: [],
  generated: null,
  webhook: null,
  linedata: null,
  lineName: '',
}




// Reducer to manage state
export const reducer = (state = initial, action) => {
  switch (action.type) {
    case ACTION.CLEAR_GENERATED:
      return { ...state, generated: null }
    case ACTION.LOAD_KEYS:
      return { ...state, data: action.data }
    case ACTION.GET_WEBHOOK:
      return { ...state, webhook: action.data }
    case ACTION.SET_WEBHOOK:
      return { ...state, webhook: action.data }
    case ACTION.GENERATE_TOKEN:
      return { ...state, generated: action.data }
    case ACTION.GET_LINEDATA:
      return { ...state, linedata: action.data }
    case ACTION.SET_LINEDATA:
      return { ...state, linedata: action.data }
    default:
      return state
  }
}

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles'
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import Loader from './../components/loader'
import { PersistGate } from 'redux-persist/integration/react'
import { DateFormatProvider } from '@ergosense/ergosense-react-common/lib/components/DateFormat'
import dayjs from 'dayjs'

// Import I18n
import './i18n'

// Import AWS Amplify
import './amplify'

// Gate components
import ErrorGateBoundary from './../components/gate/error/'

// Global components
import { Connected as Notification } from './../components/notification/';

// Custom React includes
import routes from './routes';
import { default as store, persistor, history } from './store';
import theme from './theme';

// Material UI date picker using DayJS
import DayJSUtil from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'


const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayJSUtil}>
            {/* Set the format in which dates are displayed */}
            <DateFormatProvider value={{ date: 'DD/MM/YY', time: 'HH:mm:ss', obj: dayjs }} >
              <CssBaseline />
              {/* Global crash/error handler */}
              <ErrorGateBoundary trace={Boolean(process.env.REACT_APP_TRACE_FATAL_ERROR)}>
                {/* Application notification handler */}
                <Notification />

                {/* Routed components */}
                <ConnectedRouter history={history}>
                  <Suspense fallback={<Loader/>}>
                    {/* Application routes and components */}
                    <Switch>
                      {routes.map((i, index) => {
                        const { component, ...rest } = i

                        // Skip non-component routes
                        if (!component) return null

                        const Component: React.ExoticComponent<any> = component as React.ExoticComponent<any>

                        return <Route exact {...rest} key={`${index++}`} render={() => <Component/>}/>
                      })}
                    </Switch>
                  </Suspense>
                </ConnectedRouter>
              </ErrorGateBoundary>
            </DateFormatProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App

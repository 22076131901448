import {
  SETUP,
  LOAD,
  TOGGLE_DIALOG
} from './../actions/totp';

const initial = { code: null, loading: true, showSetup: false };

export default (state = initial, action) => {
  switch (action.type) {
    case LOAD:
      return { ...state, loading: true };
    case SETUP:
      return { ...state, code: action.payload, loading: false };
    case TOGGLE_DIALOG:
      return { ...state, showSetup: action.payload };
    default:
      return state;
  }
}
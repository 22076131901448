import error from './error'

export const ACTIONS = {
  LOAD: 'loader-load'
}

/**
 * @module actions/loader
 *
 * Loader actions execute a promise
 * with loading state at start and end. Provide
 * a unique key for the loading state
 */
export default {
  /**
   * Execute a nested load operation. Will set the appropriate key and
   * invoke the callback promise
   *
   * @param {string} key - The load key
   * @param {Promise} cb - The promise
   *
   * @return {Promise}
   */
  load: (key, cb) => {
    return (dispatch, getState) => {
      dispatch({ type: ACTIONS.LOAD, [key]: true })
      return cb(dispatch, getState)
        .catch((err) => dispatch(error.exception(err)))
        .then((res) => {
          dispatch({ type: ACTIONS.LOAD, [key]: false })
          return res
        })
    }
  }
}
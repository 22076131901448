import { ACTIONS } from './../actions/loader'

const initial = {}

export default (state = initial, action) => {
  const { type, ...payload } = action

  switch (type) {
    case ACTIONS.LOAD:
      return { ...state, ...payload }
    default:
      return state
  }
}
import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'

/**
 * Full page loader. This centers a progress bar
 * in the middle of the screen.
 */
const Loader = ({ full = false, show = true, backdrop = true, ...rest }) => {
  const classes = useStyles()

  if (full && backdrop) {
    return (
      <Backdrop open={show} {...rest} className={classes.normalBackdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
    )
  } else if (full && show) {
    return (
      <Box className={classes.normalBackdrop}>
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Box color="background.offset">
      <CircularProgress color="inherit" className={classes.center}/>
    </Box>
  )
}

export default Loader

/**
 * Inline loader should be used for paper components. You must remember
 * to assign a relative position to the container it belongs to
 */
export const InlineLoader = () => {
  const classes = useStyles()

  return (
    <Box color="background.offset">
      <CircularProgress color="inherit" className={classes.centerAbsolute}/>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  normalBackdrop: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-20px',
    marginLeft: '-20px'
  },
  centerAbsolute: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-20px',
    marginLeft: '-20px'
  }
}))

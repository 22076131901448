import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import notification from './notification'

/**
 * List of action strings
 *
 * @type {object}
 */
export const ACTION = {
  REMOVE_FLOW: 'remove-flow',
  GET_FLOW: 'get-flow',
  SAVE_FLOW: 'save-flow',
  GET_FLOWS: 'get-flows'
}

/**
 * List of loading action strings
 *
 * @type {object}
 */
export const LOAD = ACTION

export const getCompanyWorkflow = (id) => {
  return loader.load(LOAD.GET_FLOW, (dispatch) => {
    dispatch({ type: ACTION.GET_FLOW, companyWorkflow: null })

    // No ID, no lookup
    if (!id) return Promise.resolve(null)

    const query = `
      query ($id: Int!) {
        companyWorkflow: getCompanyWorkflow (id: $id) {
          id
          companyId
          name
          workflow
        }
      }
    `

    return API.graphql(graphqlOperation(query, { id }))
      .then((result) => dispatch({
        type: ACTION.GET_FLOW,
        ...result.data
      }))
  })
}

export const getCompanyWorkflows = () => {
  return loader.load(LOAD.GET_FLOWS, (dispatch) => {
    const query = `
      query {
        companyWorkflows: getCompanyWorkflows {
          id
          companyId
          name
          workflow
        }
      }
    `

    return API.graphql(graphqlOperation(query))
      .then((result) => dispatch({
        type: ACTION.GET_FLOWS,
        ...result.data
      }))
  })
}

export const saveCompanyFlow = (values) => {
  return loader.load(LOAD.SAVE_FLOW, (dispatch) => {
    const updateMutation = `
      mutation ($id: Int!, $name: String!, $workflow: String!) {
        companyWorkflow: updateCompanyWorkflow (id: $id, name: $name, workflow: $workflow) {
          id
          companyId
          name
          workflow
        }
      }
    `

    const insertMutation = `
      mutation ($name: String!, $workflow: String!) {
        companyWorkflow: insertCompanyWorkflow (name: $name, workflow: $workflow) {
          id
          companyId
          name
          workflow
        }
      }
    `

    const { id, name, fn, output } = values

    const promise = id
      ? API.graphql(graphqlOperation(updateMutation, { id, name, workflow: JSON.stringify({ fn, output }) }))
      : API.graphql(graphqlOperation(insertMutation, { name, workflow: JSON.stringify({ fn, output }) }))

    return promise
      .then((result) => {
        dispatch({
          type: ACTION.SAVE_FLOW,
          ...result.data
        })

        dispatch(notification.success('Workflow updated successfully'))

        return result?.data?.companyWorkflow?.id
      })
  })
}

export const removeCompanyWorkflow = (id) => {
  return loader.load(LOAD.REMOVE_FLOW, (dispatch) => {
    const mutation = `
      mutation ($id: Int!) {
        removeCompanyWorkflow: removeCompanyWorkflow (id: $id) {
          id
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { id }))
      .then((result) => {
        dispatch({
          type: ACTION.REMOVE_FLOW,
          ...result.data
        })

        dispatch(notification.success('Workflow removed successfully'))

        dispatch(getCompanyWorkflows())

        return id
      })
  })
}

const initialState = {
}

export const reducer = (state = initialState, action) => {
  // eslint-disable-next-line
  const { type, ...rest } = action

  switch (type) {
    case ACTION.GET_FLOWS:
      return {
        ...state,
        ...rest
      }
    case ACTION.GET_FLOW:
      return {
        ...state,
        ...rest
      }
    case ACTION.SAVE_FLOW:
      return {
        ...state,
        ...rest
      }
    default:
      return state
  }
}


import Auth from '@aws-amplify/auth'
import axios from 'axios'
import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import dayjs from 'dayjs'
import notification from './notification'
import loginActions, { regenerateToken } from './login'
import { persistor } from './../application/store'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/**
 * List of action strings
 *
 * @type {object}
 */
export const ACTION = {
  BUMP_PROFILE_KEY: 'bump-profile-key',
  USER_TOTP_CODE: 'user-totp-token',
  GET_COMPANY_USERS: 'get-company-users',
  REMOVE_GROUP_FROM_USER: 'remove-group-from-user',
  ADD_GROUP_TO_USER: 'add-group-to-user',
  ADD_COMPANY_USER: 'add-company-user',
  RESEND_COMPANY_USER_PASSWD: 'resend-company-user-passwd',
  GET_COMPANIES: 'get-companies',
  IMPERSONATE: 'impersonate',
  REMOVE_USER_FROM_COMPANY: 'remove-user-from-company',
  TOGGLE_DEBUG: 'toggle-debug',
  UPDATE_COMPANY: 'update-company'
}

/**
 * List of loading action strings
 *
 * @type {object}
 */
export const LOAD = {
  UPLOAD_PROFILE_IMAGE: 'load-generate-profile-upload-url',
  TOTP_SETUP: 'totp-setup',
  VERIFY_TOTP_SETUP: 'verify-totp-setup',
  SET_PREFERRED_MFA: 'set-preferred-mfa',
  ...ACTION
}

export const bumpProfileKey = () => {
  return ({ type: ACTION.BUMP_PROFILE_KEY })
}

/**
 * Impersonate another company
 *
 * @param companyId CompanyID.
 * @param name Name.
 * @returns Async dispatch.
 */
export const impersonate = (companyId, name) => {
  return async (dispatch) => {
    // Purge any saved information
    await persistor.purge()

    // Impersonate a new company
    dispatch({ type: ACTION.IMPERSONATE, companyId, name })

    // Flush new persisted values
    await persistor.flush()

    // Navigate to dashboard and force components into a clean state.
    // The alternative is to use a "key" value on our layouts
    // Reloading is more brute force
    // window.location.replace('/')
    window.location.reload()
  }
}
/**
 * Enable debugging mode
 *
 * @returns Async dispatch
 */
export const toggleDebug = () => {
  return async (dispatch) => {
    // Impersonate a new company
    dispatch({ type: ACTION.TOGGLE_DEBUG })
  }
}

/**
 * Request list of companies. This is used
 * when super users want to impersonate.
 *
 * @param setLoading
 */
export const getCompanies = (setLoading) => {
  return Promise.resolve()
    .then(() => setLoading(true))
    .then(() => {
      const query = `
        query {
          getCompanies {
            id
            name
            archived
          }
        }
      `

      return API.graphql(graphqlOperation(query))
        .then((result) => result.data.getCompanies)
    })
    .then((res) => {
      setLoading(false)
      return res
    })
}

/**
 * Upload profile image. Accepts browser File() object
 *
 * @param {File} file - The file to upload
 */
export const uploadProfileImage = (file) => {
  return loader.load(LOAD.UPLOAD_PROFILE_IMAGE, (dispatch) => {
    const query = `
      query {
        getProfileUploadUrl {
          url
          expires
        }
      }
    `

    return API.graphql(graphqlOperation(query))
      .then((result) => result.data.getProfileUploadUrl.url)
      // Run the upload to signed URL generated
      .then((url) => axios.put(url, file, { headers: { 'Content-Type': file.type } }))
      .then((res) => (res.status === 200))
      .then(() => dispatch(bumpProfileKey()))
  })
}

/**
 * Setup TOTP verification. Generates a code to be used to add
 * a new virtual device
 *
 * @returns {Promise}
 */
export const setupTotp = () => {
  return loader.load(LOAD.TOTP_SETUP, (dispatch, getState) => {
    const { auth } = getState()

    return Auth.setupTOTP(auth.user)
      .then((res) => dispatch({ type: ACTION.USER_TOTP_CODE, data: res }))
  })
}

/**
 * Set the preferred MFA type for the user. Direct Cognito updated.
 *
 * @param {string} type - The MFA type
 * @returns {Promise}
 */
export const setPreferredMfa = (type) => {
  return loader.load(LOAD.SET_PREFERRED_MFA, (dispatch, getState) => {
    const { auth } = getState()

    // Some hacky stuff to deal with AWS/Cognito issues
    type = (type === 'SOFTWARE_TOKEN_MFA' ? 'TOTP' : type)

    return Auth.setPreferredMFA(auth.user, type)
      .then(() => Auth.currentUserPoolUser({ bypassCache: true }))
      .then((user) => dispatch(loginActions.refreshAuth(user)))
      .then(() => dispatch(notification.success('MFA settings updated successfully')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

/**
 * Verify TOTP setup by checking the code on the virtual device against
 * what the user entered.
 *
 * @param {number} code - The code
 * @returns {Promise}
 */
export const verifyTotpSetup = (code) => {
  return loader.load(LOAD.VERIFY_TOTP_SETUP, (dispatch, getState) => {
    const { auth } = getState()

    return Auth.verifyTotpToken(auth.user, code.toString())
      .then((res) => Auth.updateUserAttributes(auth.user, { 'custom:totp_created': dayjs().unix().toString() }))
      .then(() => Auth.currentUserPoolUser({ bypassCache: true }))
      .then((user) => dispatch(loginActions.refreshAuth(user)))
      .then(() => dispatch(notification.success('Virtual MFA device linked successfully')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

export const getCompanyUsers = (filters) => {
  return loader.load(LOAD.GET_COMPANY_USERS, (dispatch, getState) => {
    const state = getState()

    const { rowsPerPage } = state.table

    filters = {
      ...filters,
      fetch: rowsPerPage,
      start: Math.max(((filters.start || 0) - 1), 0) * rowsPerPage
    }

    const query = `
      query ($start: Int, $fetch: Int) {
        getCompanyUsers (start: $start, fetch: $fetch) {
          count,
          data {
            email
            lastLogin,
            groups,
            cognitoStatus
          }
        }
      }
    `

    return API.graphql(graphqlOperation(query, filters))
      .then((result) => dispatch({ type: ACTION.GET_COMPANY_USERS, ...result.data.getCompanyUsers }))
  })
}

export const removeUserFromCompany = (email) => {
  return loader.load(LOAD.REMOVE_USER_FROM_COMPANY, (dispatch) => {
    const mutation = `
      mutation ($email: String!) {
        removeUserFromCompany(email: $email) {
          email
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { email }))
      .then((result) => dispatch({ type: ACTION.REMOVE_USER_FROM_COMPANY, email }))
      .then(() => dispatch(notification.success('User successfully removed from company')))
  })
}

export const addGroupToUser = (email, group) => {
  return loader.load(LOAD.ADD_GROUP_TO_USER, (dispatch, getState) => {
    const { payload } = getState().auth

    const mutation = `
      mutation ($email: String!, $group: String!) {
        addGroupToUser(email: $email, group: $group) {
          email
          groups
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { email, group }))
      .then((result) => dispatch({ type: ACTION.ADD_GROUP_TO_USER, user: result.data.addGroupToUser }))
      .then(() => ((payload.email === email) ? dispatch(regenerateToken()) : null))
      .then(() => dispatch(notification.success('User groups updated')))
  })
}

export const removeGroupFromUser = (email, group) => {
  return loader.load(LOAD.REMOVE_GROUP_FROM_USER, (dispatch, getState) => {
    const { payload } = getState().auth

    const mutation = `
      mutation ($email: String!, $group: String!) {
        removeGroupFromUser(email: $email, group: $group) {
          email
          groups
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { email, group }))
      .then((result) => dispatch({ type: ACTION.REMOVE_GROUP_FROM_USER, user: result.data.removeGroupFromUser }))
      .then(() => ((payload.email === email) ? dispatch(regenerateToken()) : null))
      .then(() => dispatch(notification.success('User groups updated')))
  })
}

export const addCompanyUser = (email, action = null) => {
  return loader.load(LOAD.ADD_COMPANY_USER, (dispatch, getState) => {
    const mutation = `
      mutation ($email: String!, $action: CreateUserAction) {
        addCompanyUser(email: $email, action: $action) {
          email
          cognitoId
          lastLogin
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { email, action }))
      .then((result) => dispatch({ type: ACTION.ADD_COMPANY_USER, user: result.data.addCompanyUser }))
      .then(() => dispatch(notification.success('User invitation sent')))
  })
}

export const resendUserPassword = (email) => {
  return loader.load(LOAD.RESEND_COMPANY_USER_PASSWD, (dispatch, getState) => {
    const mutation = `
      mutation ($email: String!, $action: CreateUserAction) {
        addCompanyUser(email: $email, action: $action) {
          email
          cognitoId
          lastLogin
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { email, action: 'RESEND' }))
      .then((result) => dispatch({ type: ACTION.RESEND_COMPANY_USER_PASSWD, user: result.data.addCompanyUser }))
      .then(() => dispatch(notification.success('User password resent')))
  })
}

export const updateCompany = (id, name, archived) => {
  return loader.load(LOAD.UPDATE_COMPANY, (dispatch) => {
    const mutation =  `
      mutation ($id: Int!, $name: String!, $archived: Boolean) {
        updateCompany (id: $id, name: $name, archived: $archived)
      }
    `

    return API.graphql(graphqlOperation(mutation, { id, name, archived }))
      .then((result) => dispatch({ type: ACTION.UPDATE_COMPANY, data: {
          id, name
      }}))
  })
}

const initial = {
  profileKey: dayjs().unix(),
  totpSetupCode: null,
  count: 0,
  impersonate: null,
  companies: [],
  data: []
}

export const reducer = (state = initial, action) => {
  const { type, ...rest } = action

  switch (type) {
    case ACTION.REMOVE_USER_FROM_COMPANY:
      return {
        ...state,
        data: state.data.filter((i) => i.email !== rest.email)
      }
    case ACTION.GET_COMPANIES:
      return { ...state, companies: action.data }
    case ACTION.REMOVE_GROUP_FROM_USER:
    case ACTION.ADD_GROUP_TO_USER:
      return {
        ...state,
        data: state.data.map((entry) => {
          return (entry.email === action.user.email ? { ...entry, ...action.user } : entry)
        })
      }
    case ACTION.GET_COMPANY_USERS:
      return { ...state, data: action.data, count: action.count }
    case ACTION.BUMP_PROFILE_KEY:
      return { ...state, profileKey: state.profileKey + 1 }
    case ACTION.USER_TOTP_CODE:
      return { ...state, totpSetupCode: action.data }
    default:
      return state
  }
}

export const persistedReducer = persistReducer(
  { key: '_user', storage },
  (state = { impersonate: null, debugMode: false }, action) => {
    switch (action.type) {
      case ACTION.IMPERSONATE:
        return { ...state, impersonate: { companyId: action.companyId, name: action.name } }
      case ACTION.TOGGLE_DEBUG:
        return { ...state, debugMode: !state.debugMode }
      default:
        return state
    }
  })

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from './../i18n/';
import { I18n } from '@aws-amplify/core'

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });


I18n.putVocabularies({
  en: {
    // Generic labels
    'generic.cancel': 'Cancel',
    'generic.saveChanges': 'Save Changes',
    'generic.filter': 'Filter',
    'generic.password': 'Password',
    'generic.confirmPassword': 'Confirm Password',
    'generic.none': 'None',
    'generic.sms': 'SMS',
    'generic.totp': 'TOTP',
    'generic.link': 'Link',
    'generic.code': 'Code',
    'generic.detail': 'Detail',
    'generic.id': 'ID',
    'generic.name': 'Name',
    'generic.address': 'Address',
    'generic.building': 'Building',
    'generic.level': 'Level',
    'generic.floor': 'Floor',

    // Measurement units
    'unit.workspace_utilization': '%',
    'unit.workspaceUtilization': '%',
    'unit.meeting_utilization': '%',
    'unit.meetingUtilization': '%',
    'unit.count': ' ',
    'unit.occupancy': '%',
    'unit.mic': 'dbA',
    'unit.temp': '°C',
    'unit.lux': 'lux',
    'unit.humidity': '%',
    'unit.tvoc': 'ppb',
    'unit.co2': 'ppm',
    'unit.battery': '%',
    'unit.in': ' ',
    'unit.out': ' ',
    'unit.massPm1': 'ug/m3',
    'unit.massPm25': 'ug/m3',
    'unit.massPm4': 'ug/m3',
    'unit.massPm10': 'ug/m3',
    'unit.heartbeat': ' ',
    'unit.aqi': ' ',

    // Measurement keys
    'key.workspace_utilization': 'Workspace Utilization',
    'key.workspaceUtilization': 'Workspace Utilization',
    'key.mean_workspace_utilization': 'Mean Workspace Utilization',
    'key.max_workspace_utilization': 'Max Workspace Utilization',

    'key.meeting_utilization': 'Meeting Utilization',
    'key.meetingUtilization': 'Meeting Utilization',
    'key.mean_meeting_utilization': 'Mean Meeting Utilizatio',
    'key.max_meeting_utilization': 'Max Meeting Utilizatio',

    'key.count': 'Occupants',
    'key.mean_count': 'Mean Count',
    'key.max_count': 'Max Count',

    'key.occupancy': 'Occupancy',

    'key.mic': 'Sound',
    'key.mean_mic': 'Mean Sound',
    'key.max_mic': 'Max Sound',

    'key.temp': 'Temperature',
    'key.mean_temp': 'Mean Temperature',
    'key.max_temp': 'Max Temperature',

    'key.lux': 'Light',
    'key.mean_lux': 'Mean Light',
    'key.max_lux': 'Max Light',

    'key.humidity': 'Humidity',
    'key.mean_humidity': 'Mean Humidity',
    'key.max_humidity': 'Max Humidity',

    'key.tvoc': 'TVOC',
    'key.mean_tvoc': 'Mean TVOC',
    'key.max_tvoc': 'Max TVOC',

    'key.co2': 'eCO2',
    'key.mean_co2': 'Mean eCO2',
    'key.max_co2': 'Max eCO2',

    'key.battery': 'Battery',
    'key.mean_battery': 'Mean Battery',
    'key.max_battery': 'Max Battery',

    'key.massPm1': 'PM1',
    'key.massPm25': 'PM2.5',
    'key.massPm4': 'PM4',
    'key.massPm10': 'PM10',
    'key.in': 'In',
    'key.out': 'Out',

    'key.heartbeat': 'Heartbeet',
    'key.aqi': 'Aqi',

    // Permission based errors
    'permission.admin': 'Administrator',
    'permission.invalidRole': 'You don\'t have the required permissions.',
    // Sensor types TODO replace : with .
    'sensorTypes.1': 'Pebble',
    'sensorTypes.2': 'Doc',
    'sensorTypes.3': 'Abacus',
    'sensorTypes.4': 'PointGrab',
    'sensorTypes.5': 'Airlab',
    'sensorTypes.6': 'Flood Detector',
    'sensorTypes.null': '-',
    // Error messages TODO replace : with .
    'error:floorplan': 'Unable to successfully load the workspace floor plan.',
    'error:network': 'Unable to complete request. You could be offline. If this issue persists, please contact support for assistance.',
    'error:title': 'Error',
    'error:message': 'The page encountered some fatal error and we cannot recover from it',
    'error:reload': 'Reload Page',
    'error:noWorkspaces': 'No workspaces have been configured for this account',
    'error:noCompany': 'This user has not been linked to an active company',
    // Password strength component
    'password-strength.help': 'Password strength',
    'password-strength.10': 'weak',
    'password-strength.25': 'weak',
    'password-strength.50': 'moderate',
    'password-strength.75': 'good',
    'password-strength.100': 'excellent',
    // Login pages
    'login.title.login': 'Login',
    'login.title.resetPassword': 'Reset Password',
    'login.title.resetPasswordConfirm': 'Reset Password',
    'login.help.resetPassword': 'Enter the email below you wish to reset. You will receive a verification code shortly.',
    'login.help.resetPasswordConfirm': 'Enter the verification code received via the email specified.',
    'login.help.forceReset': 'Your account has been scheduled for a password reset. Please provide a new password before proceeding.',
    'login.action.forgotPassword': 'Forgot your password?',
    'login.action.changePassword': 'Change Password',
    'login.action.signIn': 'Sign in',
    'login.action.sendVerification': 'Send Verification',
    'login.action.back': 'Back to sign-in',
    'login.action.alreadyHaveCode': 'I already have a code',
    'login.action.resetPassword': 'Reset Password',
    'login.field.password': 'Password',
    'login.field.email': 'Email',
    'login.field.verification': 'Verification',
    'login.field.newPassword': 'New password',
    'login.validation.verificationRequired': 'Verification is required',
    // Generic Validations
    'validation.urlRequired': 'Valid URL is required',
    'validation.emailRequired': 'Email is required',
    'validation.emailInvalid': 'Email is invalid',
    'validation.emailInvalidPlaceholder': '%s is invalid email',
    'validation.passwordRequired': 'Password is required',
    'validation.fieldRequired': 'Field is required',
    'validation.passwordMatch': 'Passwords must match',
    'validation.confirmPasswordRequired': 'Confirmation password required',
    'validation.codeRequired': 'Code is required',
    'validation.addressRequired': 'Address is required',
    'validation.nameRequired': 'Name is required',
    'validation.buildingRequired': 'Building is required',
    'validation.floorRequired': 'Floor is required',
    'validation.fileRequired': 'File is required',
    'validation.idRequired': 'ID is required',

    // Profile Security
    'page.profile.security.title.linkVirtualDevice': 'Link virtual MFA device',
    'page.profile.security.title.virtualMFA': 'Virtual MFA Device',
    'page.profile.security.title.mfa': 'Multi-Factor-Authentication',
    'page.profile.security.title.password': 'Change Password',
    'page.profile.security.field.oldPassword': 'Old Password',
    'page.profile.security.field.preferredMfa': 'Preferred MFA',
    'page.profile.security.changeMFA': 'Change MFA',
    'page.profile.security.changePassword': 'Change Password',
    'page.profile.security.noDeviceLink': 'No device linked',
    'page.profile.security.linkedOn': 'Deviced linked',
    'page.profile.security.linkDevice': 'Link Device',
    'page.profile.security.validation.preferredMfa': 'Preferred MFA is required',
    'page.profile.security.validation.deviceLinked': 'You must link a virtual MFA device',

    // Profile General
    'page.profile.general.field.custom:job_title': 'Job Title',
    'page.profile.general.field.name': 'First Name',
    'page.profile.general.field.family_name': 'Last Name',
    'page.profile.general.validation.name': 'Firstname is required',
    'page.profile.general.validation.family_name': 'Last name is required',

    // Workspace building management
    'page.workspace.buildings.dialog.addBuilding': 'Add Building',
    'page.workspace.buildings.dialog.editBuilding': 'Edit Building',
    'page.workspace.buildings.dialog.addText': 'Create a new building for the current company. Buildings/places are at the top of the hierarchy and contains floors and areas beneath them.',
    'page.workspace.buildings.dialog.editText': 'Updating building values will not change sensor data, but will only change the labels used for display.',

    // Workspace floor management
    'page.workspace.floors.dialog.uploadImage': 'Floor Image',
    'page.workspace.floors.dialog.addFloor': 'Add Floor',
    'page.workspace.floors.dialog.editFloor': 'Edit Floor',
    'page.workspace.floors.dialog.editText': 'Editing a floor will not affect historical data. If you need to move a floor or change the floor plan, you should be creating a new floor and archive the existing one.',
    'page.workspace.floors.dialog.addText': 'Updating floor values will not change sensor data, but will only change the labels used for display.',

    // Workspace area management
    'page.workspace.areas.dialog.addArea': 'Add Area',
    'page.workspace.areas.dialog.editArea': 'Edit Area',
    'page.workspace.areas.dialog.editText': 'Editing an area will not affect historical data. If you need to move an area, you should be creating a new area and archive the existing one.',
    'page.workspace.areas.dialog.addText': 'Updating area values will not change sensor data, but will only change the labels used for display.'
  }
})

I18n.setLanguage('en')

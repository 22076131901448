/*
 | Notification
 | ------------
 | This reducer will listen for any "notifications"
 | from the app and populate the state appropriately.
 */
import { ACTIONS } from './../actions/notification';

export default (state = {}, action) => {
  switch (action.type) {
    // Add a new notification to the state
    case ACTIONS.NOTIFICATION:
      return { ...state, notification: action.payload };

    // Remove an existing notification from
    // the state.
    case ACTIONS.NOTIFICATION_DISMISS:
      return { ...state, notification: null };
    default:
      return state;
  }
}
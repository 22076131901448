import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'

/**
 * Available action types
 */
export const ACTION = {
  GET_COMPANY_NOTIFICATION_EMAIL: 'get-company-notification-email',
  UPDATE_COMPANY_NOTIFICATION_EMAIL: 'update-company-notification-email',
  INSERT_COMPANY: 'insert-company'
}

/**
 * Loading keys
 */
export const LOAD = ACTION

export const getCompanyNotificationEmailQuery = `
  query {
    getCompanyNotificationEmail {
      emailAddress
      active
    }
  }
`

export const updateCompanyNotificationEmailQuery = `
  mutation ($emailAddress: String!, $active: Boolean!) {
    updateCompanyNotificationEmail (emailAddress: $emailAddress, active: $active) {
      emailAddress
      active
    }
  }
`

export const insertCompanyQuery = `
  mutation ($name: String!) {
    insertCompany (name: $name)
  }
`

export const getCompanyNotificationEmail = () => {
  return loader.load(LOAD.GET_COMPANY_NOTIFICATION_EMAIL, (dispatch) => {
    return API.graphql(graphqlOperation(getCompanyNotificationEmailQuery))
      .then((result) => dispatch({ type: ACTION.GET_COMPANY_NOTIFICATION_EMAIL, data: result.data.getCompanyNotificationEmail }))
  })
}

export const updateCompanyNotificationEmail = (email, active) => {
  return loader.load(LOAD.UPDATE_COMPANY_NOTIFICATION_EMAIL, (dispatch) => {
    return API.graphql(graphqlOperation(updateCompanyNotificationEmailQuery, { emailAddress: email, active }))
      .then((result) => dispatch({ type: ACTION.UPDATE_COMPANY_NOTIFICATION_EMAIL, data: result.data.updateCompanyNotificationEmail }))
  })
}

export const insertCompany = (name) => {
  return loader.load(LOAD.INSERT_COMPANY, (dispatch) => {
    return API.graphql(graphqlOperation(insertCompanyQuery, { name }))
      .then((result) => dispatch({ type: ACTION.INSERT_COMPANY, data: result.data }))
  })
}

const initial = {
  notification: null
}

export const reducer = (state = initial, action) => {
  switch (action.type) {
    case ACTION.GET_COMPANY_NOTIFICATION_EMAIL:
      return {
        ...state,
        notification: action.data
      }
    case ACTION.UPDATE_COMPANY_NOTIFICATION_EMAIL:
      return {
        ...state,
        notification: action.data
      }
    case ACTION.INSERT_COMPANY:
      return {
        ...state,
        insertCompany: action.data
      }
    default:
      return state
  }
}


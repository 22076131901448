import API, { graphqlOperation } from '@aws-amplify/api'
import dayjs from 'dayjs'
import loader from './loader'

export const ACTION = {
  AGGREGATE_MEASUREMENTS: 'dashboard-aggregate-measurements'
}

export const LOAD = { ...ACTION }

export const getAggregateMeasurements = (buildingId, floorId, areaId, debug = false) => {
  const from = dayjs().subtract(7, 'days').unix()
  const to = dayjs().unix()
  const fromPrev = dayjs().subtract(14, 'days').unix()
  const toPrev = from

  const query = `
    query ($fromPrev: Int!, $toPrev: Int!, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $debug: Boolean) {
      previous: getAggregateMeasurements (from: $fromPrev, to: $toPrev, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, only: ["workspaceUtilization", "meetingUtilization"], aggregateOn: "mean", fn: "mean") {
        workspaceUtilization
        meetingUtilization
      }

      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, aggregateOn: "mean", fn: "mean") {
        workspaceUtilization
        meetingUtilization
        co2
        tvoc
        temp
        humidity
        lux
        mic
        massPm10
        massPm25
      }

      offlineCount: getOfflineDeviceCount (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, debug: $debug) {
        pebbleOnline
        pebbleOffline
        abacusOnline
        abacusOffline
        docOnline
        docOffline
        airlabOnline
        airlabOffline
        pointgrabOnline
        pointgrabOffline
      }

      batteryLevel: getBatteryLevelOverview (buildingId: $buildingId, floorId: $floorId, areaId: $areaId) {
        low
        devices
        chargeDate
      }
    }
  `

  return loader.load(LOAD.AGGREGATE_MEASUREMENTS, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, fromPrev, toPrev, buildingId, floorId, areaId, debug }))
      .then((result) => dispatch({
        type: ACTION.AGGREGATE_MEASUREMENTS,
        ...result.data
      }))
  })
}

export const reducer = (state = {}, action) => {
  // eslint-disable-next-line
  const { type, ...rest } = action

  switch (type) {
    case ACTION.AGGREGATE_MEASUREMENTS:
      return {
        ...state,
        aggregateMeasurements: rest.aggregateMeasurements[0],
        previous: rest.previous[0],
        offlineCount: rest.offlineCount,
        batteryLevel: rest.batteryLevel
      }
    default:
      return state
  }
}

import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import dayjs from 'dayjs'
import * as mutations from './../gql/mutations'
import { getFiltersFromState, createPaging } from './../helpers/'
import notification from './notification'
import util from 'util'

/**
 * List of action strings
 *
 * @type {object}
 */
export const ACTION = {
  LOAD_COVID_STATUS: 'load-covid-status',
  DOWNLOAD_COVID_STATUS: 'download-covid-status',
  GET_SURVEY_QUESTIONS: 'get-survey-questions',
  CREATE_SURVEY_QUESTION: 'create-survey-question',
  DELETE_SURVEY_QUESTION: 'delete-survey-question',
  GET_SURVEY_RESULT_SUMMARY: 'get-survey-result-summary',
  GET_NOTIFICATIONS: 'get-notifications',
  PUBLISH_NOTIFICATION: 'publish-notification',
  GET_SUGGESTIONS: 'get-suggestions'
}

/**
 * List of loading action strings
 *
 * @type {object}
 */
export const LOAD = {
  ...ACTION
}


export const queries = {
  getSuggestions: `
    query ($start: Int, $fetch: Int) {
      getSuggestions (start: $start, fetch: $fetch) {
        count
        data {
          id
          email
          created
          text
          type
        }
      }
    }
  `,
  getNotifications: `
    query ($start: Int, $fetch: Int) {
      getNotifications (start: $start, fetch: $fetch) {
        count
        data {
          id
          created
          message
        }
      }
    }
  `,
  getDailyCovidStatus: `
    query ($from: Int!, $to: Int!) {
      getDailyCovidStatus (from: $from, to: $to) {
        count
        data {
          year
          month
          day
          entries
          passed
        }
      }
    }
  `,
  generateSurveyQuestionSummary: `
    query ($surveyId: Int!) {
      generateSurveyQuestionSummary (surveyId: $surveyId) {
        responded
        answerId
        answerText
        averageScale
      }

      getSurveyQuestion (surveyId: $surveyId) {
        id
        created
        expires
        type
        text
        answers {
          id
          text
        },
        users,
        responded
      }
    }
  `,
  getSurveyQuestions: `
    query {
      getSurveyQuestions (expires: 0) {
        id
        created
        expires
        type
        text
        answers {
          id
          text
        },
        users,
        responded
      }
    }
  `
}

export const getSuggestions = () => {
  return loader.load(LOAD.GET_SUGGESTIONS, (dispatch, getState) => {
    const state = getState()
    const { rowsPerPage } = state.table
    let filters = { ...state.router.location.query }

    return API.graphql(graphqlOperation(queries.getSuggestions, createPaging(filters, rowsPerPage)))
      .then((result) => dispatch({ type: ACTION.GET_SUGGESTIONS, ...result.data.getSuggestions }))
  })
}

export const publishNotification = (message) => {
  return loader.load(LOAD.PUBLISH_NOTIFICATION, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.publishNotification, { message }))
      .then(() => dispatch(notification.success('Message published successfully')))
      .then(() => dispatch(getNotifications()))
  })
}

export const getNotifications = () => {
  return loader.load(LOAD.GET_NOTIFICATIONS, (dispatch, getState) => {
    const state = getState()
    const { rowsPerPage } = state.table
    let filters = { ...state.router.location.query }

    return API.graphql(graphqlOperation(queries.getNotifications, createPaging(filters, rowsPerPage)))
      .then((result) => dispatch({ type: ACTION.GET_NOTIFICATIONS, ...result.data.getNotifications }))
  })
}

export const deleteSurveyQuestion = (id) => {
  return loader.load(LOAD.DELETE_SURVEY_QUESTION, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.updateSurveyQuestion, { id, expires: dayjs().unix() }))
      .then((result) => dispatch({ type: ACTION.DELETE_SURVEY_QUESTION, data: result.data.updateSurveyQuestion }))
      .then(() => dispatch(notification.success('Survey set to expire successfully')))
  })
}

export const createSurveyQuestion = (values) => {
  return loader.load(LOAD.CREATE_SURVEY_QUESTION, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.createSurveyQuestion, { text: values.question, ...values }))
      .then((result) => dispatch({ type: ACTION.CREATE_SURVEY_QUESTION, data: result.data.createSurveyQuestion }))
      .then(() => dispatch(notification.success('Survey created successfully')))
  })
}

export const getDailyCovidStatus = () => {
  return loader.load(LOAD.LOAD_COVID_STATUS, (dispatch, getState) => {
    const state = getState()

    const filters = getFiltersFromState(state)

    const now = dayjs()
    // Default for last 7 days
    const from = now.subtract(7, 'days').unix()
    const to = now.unix()

    return API.graphql(graphqlOperation(queries.getDailyCovidStatus, { from, to, ...filters }))
      .then((result) => dispatch({ type: ACTION.LOAD_COVID_STATUS, data: result.data.getDailyCovidStatus }))
  })
}

export const downloadCovidStatus = (day) => {
  return loader.load(LOAD.DOWNLOAD_COVID_STATUS, (dispatch) => {
    const from = day.startOf('day').unix()
    const to = day.endOf('day').unix()

    return API.graphql(graphqlOperation(mutations.downloadCovidStatus, { from, to }))
      .then((result) => {
        return Promise.resolve()
          .then(() => dispatch({ type: ACTION.DOWNLOAD_COVID_STATUS, data: result.data.downloadCovidStatus }))
          .then(() => dispatch(notification.success(util.format('Mail sent to %s', result.data.downloadCovidStatus.sent))))
      })
  })
}

export const getSurveyQuestions = () => {
  return loader.load(LOAD.GET_SURVEY_QUESTIONS, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getSurveyQuestions))
      .then((result) => dispatch({ type: ACTION.GET_SURVEY_QUESTIONS, data: result.data.getSurveyQuestions }))
  })
}

export const generateSurveyQuestionSummary = (surveyId) => {
  return loader.load(LOAD.GET_SURVEY_RESULT_SUMMARY, (dispatch) => {
    return API.graphql(graphqlOperation(queries.generateSurveyQuestionSummary, { surveyId }))
      .then((result) => {
        dispatch({ type: ACTION.GET_SURVEY_RESULT_SUMMARY, surveyId: result.data.getSurveyQuestion.id, data: result.data.generateSurveyQuestionSummary })
        dispatch({ type: ACTION.GET_SURVEY_QUESTIONS, data: [result.data.getSurveyQuestion] })
        return
      })
  })
}

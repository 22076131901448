import notification from './notification'
import { I18n } from '@aws-amplify/core'
import config from './../application/config'

/**
 * @module actions/error
 */

export default {
  /**
   * Helper action to handle any error you want to display
   *
   * @param {string} err - The error string
   *
   * @return {function}
   */
  error: (err) => {
    return (dispatch) => {
      // TODO pretty up
      console.log(err)

      // Raise notification
      dispatch(notification.error(err))
    }
  },
  gql: (err) => {
    return (dispatch) => {
      err.errors && err.errors.forEach((e) => console.error(e.message))

      // Raise notification
      // TODO better
      dispatch(notification.error('Request error'))
    }
  },
  /**
   * Helper action to handle any exceptions
   *
   * @param {object} err - The exception
   *
   * @return {function}
   */
  exception: (err) => {
    return (dispatch) => {
      // TODO pretty up
      config.debug && console.log(err)

      // Raise notification
      dispatch(notification.error(I18n.get('error:network')))
    }
  }
}

import API, { graphqlOperation } from '@aws-amplify/api'
import { push } from 'connected-react-router'
import loader from './loader'
import qs from 'querystring'
import notification from './notification'

const GQL_SENSOR_TYPE = {
  0: 'UNKNOWN',
  1: 'PEBBLE',
  2: 'DOC',
  3: 'ABACUS',
  4: 'POINTGRAB',
  5: 'AIRLAB'
}

/**
 * Available action types
 */
export const ACTION = {
  SENSORS_GET_DATA: 'sensors-get-data',
  SENSORS_GET_DETAIL: 'sensors-get-detail',
  UPDATE_SENSOR: 'sensors-update-sensor',
  REBOOT_DEVICE: 'sensors-reboot-device',
  RELEASE_DEVICE: 'sensors-release-device'
}

/**
 * Loading keys
 */
export const LOAD = ACTION

/**
 * Retrieve sensor detail
 *
 * @type {string}
 */
export const getSensorQuery = `
  query ($id: String!) {
    getSensor (id: $id) {
      id
      lastEvent
      identity
      type
      connected
      battery
      firmware
      disabled
      publishInterval
      sensorPublishInterval
      companyPublishInterval
    }
  }
`

/**
 * Update sensor entry
 *
 * @type {string}
 */
export const updateSensorMutation = `
  mutation ($id: ID!, $disabled: Boolean, $publishInterval: Int) {
    updateSensor (id: $id, disabled: $disabled, publishInterval: $publishInterval) {
      id
      lastEvent
      type
      firmware
      identity
      disabled,
      publishInterval,
      sensorPublishInterval,
      companyPublishInterval
    }
  }
`

/**
 * Reboot sensor device
 *
 * @type {string}
 */
export const resetSensorMutation = `
  mutation ($id: ID!) {
    resetDevice(id: $id) {
      success
    }
  }
`

/**
 * Sensor lookup and pagination lookup. For searching
 * all company related sensors
 *
 * @type {string}
 */
export const getSensorsQuery = `
  query ($buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $id: String, $identity: String, $start: Int, $fetch: Int, $orderBy: String, $sort: Boolean, $type: [SensorType!], $lowBat: Boolean, $offline: Boolean, $unassigned: Boolean, $debug: Boolean) {
sensors: getSensors (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, id: $id, identity: $identity, start: $start, fetch: $fetch, orderBy: $orderBy, sort: $sort, type: $type, lowBat: $lowBat, offline: $offline, unassigned: $unassigned, debug: $debug) {
      count
      data {
        id
        lastEvent
        type
        connected
        battery
        building {
          name
        }
        floor {
          name
        }
        area {
          name
        }
      }
      orderBy
      sort
    }
  }
`

const identityMatch = RegExp(/^identity.*/)

export const resetDevice = (id) => {
  return loader.load(LOAD.REBOOT_DEVICE, (dispatch) => {
    return API.graphql(graphqlOperation(resetSensorMutation, { id }))
      .then(() => dispatch(notification.success('Device rebooted successfully')))
  })
}

export const setPage = (page) => {
  return (dispatch, getState) => {
    const state = getState()
    const filters = state.router.location.query

    dispatch(setFilters({ ...filters, start: page }))
  }
}

export const updateFilters = (update) => {
  return (dispatch, getState) => {
    const state = getState()
    const filters = state.router.location.query

    dispatch(setFilters({ ...filters, ...update }))
  }
}

export const setFilters = (filters) => {
  return (dispatch) => {
    dispatch(push({ search: qs.stringify(filters) }))
  }
}

export const updateSensor = (id, update) => {
  const { disabled, publishInterval } = update

  return loader.load(LOAD.UPDATE_SENSOR, (dispatch) => {
    return API.graphql(graphqlOperation(updateSensorMutation, { id, disabled, publishInterval }))
      .then((result) => {
        dispatch({ type: ACTION.UPDATE_SENSOR, data: result.data.updateSensor })
      })
  })
}

/**
 * Release device from company account, make it usable somewhere else.
 */
export const releaseDevice = (id) => {
  return loader.load(LOAD.RELEASE_DEVICE, (dispatch) => {
    const mutation = `
      mutation ($id: ID!) {
        releaseSensor (id: $id) {
          id
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { id }))
      .then((result) => {
        dispatch({ type: ACTION.RELEASE_DEVICE, data: result.data.releaseSensor })
        dispatch(notification.success('Device removed successfully'))
      })
  })
}

export const getSensor = (id) => {
  return loader.load(LOAD.SENSORS_GET_DETAIL, (dispatch) => {
    return API.graphql(graphqlOperation(getSensorQuery, { id }))
      .then((result) => {
        dispatch({ type: ACTION.SENSORS_GET_DETAIL, data: result.data.getSensor })
      })
  })
}

export const getSensorsData = (buildingId, floorId, areaId, id, deviceType, filters = {}, lowBat, offline, unassigned, debug) => {
  return loader.load(LOAD.SENSORS_GET_DATA, (dispatch, getState) => {
    const state = getState()

    const { rowsPerPage } = state.table

    // If user specified an identity, we will instead query
    // on that field instead of ID
    const useIdentity = id && identityMatch.test(id)

    // Gather additional filters from the selected criteria
    filters = {
      ...filters,
      id: useIdentity ? undefined : id,
      identity: useIdentity ? decodeURIComponent(filters.id) : undefined,
      type: deviceType && deviceType.length ? deviceType : undefined,
      lowBat: lowBat ?? false,
      unassigned: unassigned ?? false,
      offline: offline ?? false,
      buildingId,
      floorId,
      areaId,
      debug
    }

    // Map type to GQL sensor type
    filters.type = filters.type?.map((t) => GQL_SENSOR_TYPE[t])

    return API.graphql(graphqlOperation(getSensorsQuery, { ...filters, start: Math.max(((filters.start || 0) - 1), 0) * rowsPerPage, fetch: rowsPerPage }))
      .then((result) => {
        dispatch({
          type: ACTION.SENSORS_GET_DATA,
          data: result.data.sensors.data,
          count: result.data.sensors.count,
          orderBy: result.data.sensors.orderBy,
          // Use 1 and 0 to handle query string values better
          sort: (result.data.sensors.sort ? 1 : 0)
        })
      })
  })
}

const initial = {
  data: [],
  count: 0,
  detail: {}
}

export const reducer = (state = initial, action) => {
  const { type, ...rest } = action

  switch (type) {
    case ACTION.RELEASE_DEVICE:
      return {
        ...state,
        data: state.data.filter((i) => i.id !== rest.data.id)
      }
    case ACTION.UPDATE_SENSOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.data.id]: rest.data
        }
      }
    case ACTION.SENSORS_GET_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.data.id]: rest.data
        }
      }
    case ACTION.SENSORS_GET_DATA:
      return {
        ...state,
        ...rest
      }
    default:
      return state;
  }
}

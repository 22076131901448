import * as Constants from './../application/constants'

const translations = {
  en: {
    translation: {
      sensorTypes: {
        1: 'Pebble',
        2: 'Doc',
        3: 'Abacus',
        4: 'PointGrab',
        5: 'Airlab'
      },
      types: {
        workspace_utilization: 'Workspace Utilization',
        meeting_utilization: 'Meeting Utilization',
        max_count: 'Max Count',
        count: 'Count',
        occupancy: 'Occupancy',
        mic: 'Sound',
        temp: 'Temperature',
        lux: 'Light',
        humidity: 'Humidity',
        tvoc: 'TVOC',
        co2: 'CO2',
        fixedAssets: 'Fixed Assets',
        mobileAssets: 'Mobile Assets',
        battery: 'Battery',
        conPm05: 'PM 0.5',
        conPm1: 'PM 1',
        conPm10: 'PM 10',
        conPm25: 'PM 2.5',
        conPm4: 'PM 4',
        massPm1: 'PM 1',
        massPm10: 'PM 10',
        massPm25: 'PM 2.5',
        massPm4: 'PM 4'
      },
      detail: 'Detail',
      cancel: 'Cancel',
      view: 'View',
      apply: 'Apply',
      analyze: 'Analyse',
      percentage: 'Percentage',
      day: 'Day',
      '10m': '10m',
      '15m': '15m',
      '1m': '1m',
      hour: 'Hour',
      week: 'Week',
      month: 'Month',
      linear: 'Linear',
      logarithmic: 'Logarithmic',
      noData: 'No data found'
    },
    floor: {
      0: 'Ground Floor',
      1: 'First Floor',
      2: 'Second Floor',
      3: 'Third Floor',
      4: 'Fourth Floor',
      5: 'Fifth Floor',
      6: 'Sixth Floor'
    },
    menu: {
      [Constants.PATH.HOME]: 'Dashboard',
      [Constants.PATH.WORKSPACE]: 'Workspace',
      [Constants.PATH.ASSETS]: 'Assets',
      [Constants.PATH.DETAIL]: 'Analytics',
      [Constants.PATH.WORKSPACE_BUILDING]: 'Buildings',
      [Constants.PATH.WORKSPACE_FLOORS]: 'Floors',
      [Constants.PATH.WORKSPACE_AREAS]: 'Areas'
    },
    healthScore: {
      [Constants.STATUS.OK]: 'Your workplace score is acceptable, keep at it.',
      [Constants.STATUS.WARNING]: 'Your workspace can be improved.',
      [Constants.STATUS.ERROR]: 'Your workspace is in dire need of attention.'
    },
    assets: {
      title: {
        rfid: 'RFID',
        present: 'Present',
        lastSeen: 'Last seen',
        workspace: 'Workspace'
      },
      searchAll: 'Search all'
    },
    summary: {
      workspace_utilization: {
        [Constants.STATUS.OK]: 'Your workspace occupancy is acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace occupancy is low, you should investigate',
        [Constants.STATUS.ERROR]: 'Your workspace is not being utilised to capacity'
      },
      meeting_utilization: {
        [Constants.STATUS.OK]: 'Your workspace occupancy is acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace occupancy is low, you should investigate',
        [Constants.STATUS.ERROR]: 'Your workspace is not being utilised to capacity'
      },
      occupancy: {
        [Constants.STATUS.OK]: 'Your workspace occupancy is acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace occupancy is low, you should investigate',
        [Constants.STATUS.ERROR]: 'Your workspace is not being utilised to capacity'
      },
      mic: {
        [Constants.STATUS.OK]: 'Your workspace sound levels are adequate',
        [Constants.STATUS.WARNING]: 'Your workspace sound levels are suboptimal',
        [Constants.STATUS.ERROR]: 'Your workspace sound levels are not ideal for an office environment'
      },
      temp: {
        [Constants.STATUS.OK]: 'Your workspace temperature level is acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace temperature is not ideal',
        [Constants.STATUS.ERROR]: 'Your workspace temperature is not suitable for employees'
      },
      lux: {
        [Constants.STATUS.OK]: 'Your workspace light levels are acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace light levels are a little dim',
        [Constants.STATUS.ERROR]: 'Your workspace light levels are too low'
      },
      humidity: {
        [Constants.STATUS.OK]: 'Your workspace humidity levels are adequate',
        [Constants.STATUS.WARNING]: 'Your workspace is slightly too humid',
        [Constants.STATUS.ERROR]: 'Your workspace humidty is too high'
      },
      tvoc: {
        [Constants.STATUS.OK]: 'Your workspace TVOC levels are acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace TVOC levels are approaching dangerous levels',
        [Constants.STATUS.ERROR]: 'Your workspace TVOC levels are too high for constant exposure'
      },
      co2: {
        [Constants.STATUS.OK]: 'Your workspace CO2 levels are acceptable',
        [Constants.STATUS.WARNING]: 'Your workspace CO2 levels are not ideal',
        [Constants.STATUS.ERROR]: 'Your workspace CO2 levels are too high'
      },
      fixedAssets: {
        [Constants.STATUS.OK]: 'Most of your assets are within your workspace',
        [Constants.STATUS.WARNING]: 'Some of your assets are missing, you need to reconcile this',
        [Constants.STATUS.ERROR]: 'Too many of your assets are missing from your workspace'
      },
      mobileAssets: {
        [Constants.STATUS.OK]: 'Your mobile assets are within your workspace',
        [Constants.STATUS.WARNING]: 'Most of your mobile assets are missing, you need to reconcile this',
        [Constants.STATUS.ERROR]: 'Too many of your mobile assets are missing'
      }
    },
    error: {
      title: 'Error',
      message: 'The page encountered some fatal error and we cannot recover from it',
      reload: 'Reload Page',
      noWorkspaces: 'No workspaces have been configured for this account',
      noCompany: 'This user has not been linked to an active company'
    },
    detail: {
      title: 'Detail',
      trend: 'Trend',
      distribution: 'Distribution',
      insights: 'Insights',
      distributionNote: 'Data for the selected period distributed daily over one week',
      insightsNote: 'Displays aggregate values for the data in the selected timeframe',
      groupBy: 'Group By',
      scale: 'Scale',
      legend: 'Legend',
      groupByText: 'Data will be groups by the selected interval',
      scaleText: 'Using a "logarithmic" scale will ensure data with vastly different values can be visualized on the same graph',
      legendText: 'Select the measurements you wish to display on the graph',
      filterPlaceholder: 'Filter on desk (eg. 123)',
      thresholdHigh: 'High',
      thresholdLow: 'Low',
      measurement: 'Measurement',
      min: 'Min',
      max: 'Max',
      avg: 'Average',
      autoRefresh: 'Refresh automatically'
    },
    login: {
      title: 'Sign in',
      totp: {
        code: {
          label: 'One-Time Pin',
          required: 'OTP code is required'
        },
        verify: 'Verify'
      }
    },
    dashboard: {
      title: 'Dashboard',
      healthScoreTitle: 'Facility Health Score',
      viewing: 'You are viewing: %{name}',
      detail: 'Detail'
    },
    workspace: {
      title: 'Workspace',
      refreshing: 'Loading workspace...'
    },
    dateRange: {
      info: 'Select the date range you are interested in viewing',
      lastWeek: '7 days',
      today: '24 hours',
      lastMonth: '30 days'
    },
    sensors: {
      graphTitle: '24 hours',
      serial: 'Serial',
      type: 'Type',
      lastActivity: 'Last activity'
    },
    desks: {
      title: 'Desk',
      capacity: 'Capacity',
      lastActivity: 'Last activity',
      sensorId: 'ID',
      sensorIdDec: 'ID (decimal)',
      sensorStatus: 'Status',
      sensorOnline: 'Online',
      sensorOffline: 'Offline',
      sensorFirmware: 'Firmware',
      noSensors: 'No sensors associated with this desk',
      sensorType: 'Type',
      sensorIdentity: 'Identity'
    }
  }
}

export default translations

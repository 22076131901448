import API, { graphqlOperation } from '@aws-amplify/api'
import dayjs from 'dayjs'
import error from './error'

export const ACTIONS = {
  DATE_RANGE: 'detail-range',
  LOAD: 'detail-load',
  LOADING: 'detail-loading',
  LOADING_DIST: 'detail-loading-dist',
  LOAD_DIST: 'detail-load-dist',
  ACTIVE: 'detail-active',
  GROUP_BY: 'detail-group-by',
  FILTER_DESK: 'detail-filter-desks'
}

const actions = {
  filterDesk: (desk) => {
    return (dispatch) => {
      dispatch({ type: ACTIONS.FILTER_DESK, desk: desk })
    }
  },
  setGroupBy: (groupBy) => {
    return (dispatch) => {
      dispatch({ type: ACTIONS.GROUP_BY, groupBy })
      dispatch(actions.getMeasurements())
    }
  },
  setActive: (active) => ({ type: ACTIONS.ACTIVE, active }),
  getMeasurements: () => {
    return (dispatch, getState) => {
      const { detail, _workspace, router } = getState()

      const { building, area, floor } = _workspace.selected
      let { to, from, groupBy, desk } = detail

      desk = desk || (router && router.location && router.location.query && router.location.query.desk)

      const now = dayjs()
      to = (to.diff(now, 'day') === 0) ? now.unix() : dayjs(to).endOf('day').unix()
      from = dayjs(from).startOf('day').unix()

      desk = desk ? parseInt(desk).toString() : null

      // Figure out groupBy for Influx lookups
      switch (groupBy) {
        case '1m':
          groupBy = '1m'
          break
        case '10m':
          groupBy = '10m'
          break
        case '15m':
          groupBy = '15m'
          break
        case 'hour':
          groupBy = '1h'
          break
        case 'day':
          groupBy = '1d'
          break
        default:
          groupBy = '30d'
          break
      }

      dispatch({ type: ACTIONS.LOADING, payload: true })

      const query = `
        query ($desk: Int, $from: Int!, $to: Int!, $building: Int!, $floor: Int!, $area: Int!, $groupBy: String, $fill: Boolean) {
          getMeasurementsGeneric(deskId: $desk, from: $from, to: $to, buildingId: [$building], floorId: [$floor], areaId: [$area], groupBy: $groupBy, fill: $fill) {
            time
            co2
            humidity
            lux
            mic
            workspace_utilization: workspaceUtilization
            meeting_utilization: meetingUtilization
            temp
            tvoc
            count
            massPm1
            massPm10
            massPm25
            massPm4
            battery
          }

          getMeasurementsSummary(deskId: $desk, from: $from, to: $to, building: $building, floor: $floor, areaId: $area) {
            min_co2,
            max_co2,
            mean_co2,
            min_humidity,
            max_humidity,
            mean_humidity,
            min_lux,
            max_lux,
            mean_lux,
            min_mic,
            max_mic,
            mean_mic,
            min_workspace_utilization,
            max_workspace_utilization,
            mean_workspace_utilization,
            min_meeting_utilization,
            max_meeting_utilization,
            mean_workspace_utilization,
            min_temp,
            max_temp,
            mean_temp,
            min_tvoc,
            max_tvoc,
            mean_tvoc,
            min_count,
            max_count,
            mean_count
          }
        }
      `

      const opts = {
        from,
        to,
        building,
        floor: floor || 0,
        area: area || 0,
        desk: desk || null,
        groupBy,
        fill: true
      }

      return API.graphql(graphqlOperation(query, opts))
        .then((result) => dispatch({ type: ACTIONS.LOAD, payload: result.data.getMeasurementsGeneric, overview: result.data.getMeasurementsSummary }))
        .catch((err) => dispatch(error.gql(err)))
        .then(() => dispatch({ type: ACTIONS.LOADING, payload: false }))
    }
  },
  setDateRange: (from, to) => {
    return (dispatch) => {
      dispatch({ type: ACTIONS.DATE_RANGE, payload: { from, to } })
      dispatch(actions.getMeasurements())
    }
  }
}

export default actions

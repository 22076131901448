export default {
  debug: !(process.env.REACT_APP_DEBUG === '0' || process.env.REACT_APP_DEBUG === 'false'),
  auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
  },
  mapbox: process.env.REACT_APP_MAPBOX_TOKEN,
  nodeRed: {
    domain: process.env.REACT_APP_NR_DOMAIN_NAME
  }
}
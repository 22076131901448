import Auth from '@aws-amplify/auth'
import notification from './notification'
import error from './error'
import loader from './loader'

export const ACTIONS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  COMPANY_ID: 'company-id'
}

export const LOAD = {
  CHANGE_PASSWORD: 'user-change-password',
  UPDATE_ATTRIBUTES: 'user-update-attributes'
}

const actions = {
  setCompanyId: (companyId) => ({ type: ACTIONS.COMPANY_ID, companyId }),
  /**
   * Refresh the user auth. This will pull the token
   * from storage or generate a new one and check if
   * the user has permission to view the platform
   *
   * @param {object} user - The user object override
   *
   * @return {Promise}
   */
  refreshAuth: (user = null) => {
    return (dispatch) => {
      return Promise.resolve(user)
        .then((user) => (!user ? Auth.currentUserPoolUser() : user))
        .then((user) => {
          console.log(user)
          dispatch({ type: ACTIONS.LOGIN, payload: user })
        })
        .catch((err) => {
          dispatch(error.exception(err))

          // Immediately terminate the session, the user encountered
          // a session error and should not be allowed to continue
          dispatch({ type: ACTIONS.LOGOUT })
        })
    }
  },
  login: (username, password) => {
    return (dispatch) => {
      return Auth.signIn(username.toLowerCase(), password)
        .then((user) => dispatch(actions.refreshAuth(user)))
        .catch((err) => dispatch(notification.error(err.message)));
    };
  },
  logout: () => {
    return (dispatch) => {
      return Auth.signOut()
        .then(() => dispatch({ type: ACTIONS.LOGOUT }))
        .catch((err) => dispatch(notification.error(err.message)));
    };
  },
  verifyToken: (user, code) => {
    return (dispatch) => {
      return Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
        // Specify null as auth user to force a refresh
        .then(() => dispatch(actions.refreshAuth(null)))
        .catch((err) => dispatch(notification.error(err.message)));
    }
  },
  completeChangePassword: (password) => {
    return (dispatch, getState) => {
      // Use logged in user
      const { user } = getState()['auth']

      return Auth.completeNewPassword(user, password, {})
        .then((user) => dispatch(actions.refreshAuth(user)))
        .catch((err) => dispatch(notification.error(err.message)));
    };
  },
  changePassword: (user, oldPassword, newPassword) => {
    return (dispatch) => {
      return Auth.changePassword(user, oldPassword, newPassword)
        .then((user) => dispatch(actions.refreshAuth(user)))
        // TODO i18n
        .then(() => dispatch(notification.success('Password changed successfully')))
        .catch((err) => dispatch(notification.error(err.message)));
    }
  },
  forgotPassword: (username) => {
    return (dispatch) => {
      return Auth.forgotPassword(username.toLowerCase())
        .catch((err) => dispatch(notification.error(err.message)))
    }
  },
  forgotPasswordReset: (username, otp, password) => {
    return (dispatch) => {
      return Auth.forgotPasswordSubmit(username.toLowerCase(), otp, password)
        .then(() => dispatch(notification.success('Password changed successfully')))
        .catch((err) => dispatch(notification.error(err.message)))
    }
  }
};

export default actions;

/**
 * Change active users password.
 *
 * @param {string} oldPassword - The existing password
 * @param {string} newPassword - The desired password
 *
 * @return {Promise}
 */
export const changePassword = (oldPassword, newPassword) => {
  return loader.load(LOAD.CHANGE_PASSWORD, (dispatch, getState) => {
    const { auth } = getState()

    return Auth.changePassword(auth.user, oldPassword, newPassword)
      .then(() => dispatch(notification.success('Password changed successfully')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

/**
 * Regenerate authentication token. This will reload
 * user groups and attributes
 *
 * @return {Promise}
 */
export const regenerateToken = () => {
  return (dispatch, getState) => {
    const { auth } = getState()

    const user = auth.user
    const session = user.signInUserSession

    const refresh = new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (err, data) => {
        return err ? reject(err) : resolve(data)
      })
    })

    return refresh.then((data) => dispatch(actions.refreshAuth()))
  }
}

export const updateAttributes = (attributes) => {
  return loader.load(LOAD.UPDATE_ATTRIBUTES, (dispatch, getState) => {
    const { auth } = getState()
    const current = auth.user.attributes

    attributes = {
      ...current,
      family_name: attributes.family_name,
      name: attributes.name,
      'custom:job_title': attributes['custom:job_title'],
      'custom:totp_created': attributes['custom:totp_created']
    }

    // If no topt_created specified, remove it from the update
    if (!attributes['custom:totp_created']) delete attributes['custom:totp_created']

    return Auth.updateUserAttributes(auth.user, attributes)
      .then(() => Auth.currentUserPoolUser({ bypassCache: true }))
      .then((user) => dispatch(actions.refreshAuth(user)))
      .then(() => dispatch(notification.success('User attributes updated')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

const initial = {
  user: null,
  challengeName: null,
  authed: false,
  init: false,
  payload: null,
  companyId: null
}

export const reducer = (state = initial, action) => {
  switch (action.type) {
    case ACTIONS.COMPANY_ID:
      return { ...state, companyId: action.companyId }
    case ACTIONS.LOGOUT:
      return { ...state, user: null, authed: false, init: true, payload: null }
    case ACTIONS.LOGIN:
      const attributes = action.payload.signInUserSession && action.payload.signInUserSession.idToken.payload
      const companyId = attributes && attributes.companyId
      const challenge = action.payload.challengeName

      // No company ID set, user cannot use the platform as it's not associated with
      // any company.
      if (!companyId && challenge !== 'NEW_PASSWORD_REQUIRED') throw new Error('User not associated with company')

      return {
        ...state,
        user: action.payload,
        challengeName: action.payload && action.payload.challengeName,
        authed: !!action.payload.signInUserSession,
        init: true,
        payload: attributes,
        companyId
      }
    default:
      return state
  }
}

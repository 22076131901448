import { ACTION } from './../actions/sensy'
import { indexer } from './../helpers/'

const initial = {
  surveys: {
    data: [],
    summaryById: {},
    byId: {}
  },
  suggestions: {
    data: [],
    count: 0
  },
  covid: {
    count: 0,
    data: [],
    sent: null
  },
  notifications: {
    count: 0,
    data: []
  }
}

export default (state = initial, action) => {
  switch (action.type) {
    /**
     * Get notifications
     */
    case ACTION.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          count: action.count,
          data: action.data
        }
      }
    /**
     * Save specific survey summaries
     * by ID index
     */
    case ACTION.GET_SURVEY_RESULT_SUMMARY:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          summaryById: {
            ...state.surveys.summaryById,
            [action.surveyId]: action.data
          }
        }
      }
    /**
     * Replace updated record without having to
     * do a full reload of the page.
     */
    case ACTION.DELETE_SURVEY_QUESTION:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: state.surveys.data.map((survey) => {
            // Replace updated record
            if (survey.id === action.data.id) {
              return action.data
            } else {
              return survey
            }
          })
        }
      }
    /**
     * Include newly created survey in list
     * of surveys. This will stop us from doing another
     * lookup.
     */
    case ACTION.CREATE_SURVEY_QUESTION:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: state.surveys.data.concat([action.data])
        }
      }
    case ACTION.GET_SURVEY_QUESTIONS:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          data: action.data,
          byId: indexer(action.data, 'id')
        }
      }
    case ACTION.DOWNLOAD_COVID_STATUS:
      return {
        ...state,
        covid: { ...state.covid, sent: action.data.sent }
      }
    case ACTION.LOAD_COVID_STATUS:
      return {
        ...state,
        covid: { ...state.covid, ...action.data }
      }
    default:
      return state
  }
}

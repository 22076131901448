import { ACTIONS } from './../actions/detail'
import dayjs from 'dayjs'

const initial = {
  from: dayjs().subtract('7', 'days'), // Default is for a week
  to: dayjs(),
  measurements: [],
  overview: [],
  distribution: {},
  loading: false,
  loadingDist: false,
  active: [],
  groupBy: 'hour',
  desk: null
}

const _reduce = (accum, current) => {
  const { time, ...other } = current

  const timeObject = dayjs.unix(time)
  const hour = parseInt(timeObject.format('H'), 10)
  const day = timeObject.format('ddd')

  Object.keys(other).forEach((key) => {
    accum[key] = accum[key] || {}
    accum[key][hour] = {
      ...accum[key][hour],
      [day]: ((accum[key][hour] && accum[key][hour][day]) || []).concat(other[key])
    }
  })

  return accum
}

export default (state = initial, action) => {
  switch (action.type) {
    case ACTIONS.FILTER_DESK:
      return { ...state, desk: action.desk }
    case ACTIONS.GROUP_BY:
      return { ...state, groupBy: action.groupBy }
    case ACTIONS.ACTIVE:
      return { ...state, active: action.active }
    case ACTIONS.LOADING:
      return { ...state, loading: Boolean(action.payload) }
    case ACTIONS.LOADING_DIST:
      return { ...state, loadingDist: Boolean(action.payload) }
    case ACTIONS.LOAD:
      const distribution = action.payload.reduce(_reduce, {})

      // Complicated looping to figure out averages
      // over the distribution
      Object.keys(distribution).forEach((metric) => {
        Object.keys(distribution[metric]).forEach((hour) => {
          Object.keys(distribution[metric][hour]).forEach((day) => {
            // Filter out null data points
            const wd = distribution[metric][hour][day].filter((i) => (i !== null))
            distribution[metric][hour][day] = wd.length ? (wd.reduce((accum, current) => (accum + current), 0) / wd.length) : 0
          })
        })
      })

      return { ...state, measurements: action.payload, overview: action.overview, distribution }
    case ACTIONS.DATE_RANGE:
      return { ...state, ...action.payload }
    default:
      return state;
  }
}

/*
 | Entry point
 | -----------
 | Consider this file the front controller
 | to our react application
 */
import ReactDOM from 'react-dom'
import App from './application/app'

// Global style overrides
// TODO we should try and do this in CSSBaseline
import './index.css'

ReactDOM.render(<App />, document.getElementById('root'))

import Auth from '@aws-amplify/auth';
import login from './login';

export const SETUP = 'totp-setup';
export const LOAD = 'totp-loading';
export const TOGGLE_DIALOG = 'totp-toggle-dialog';

export default {
  disable: () => {
    return (dispatch, getState) => {
      const { user } = getState()['auth'];

      return Auth.setPreferredMFA(user, 'NOMFA')
        .then(() => dispatch(login.refreshAuth()));
    }
  },
  showSetup: () => ({ type: TOGGLE_DIALOG, payload: true }),
  dismissSetup: () => ({ type: TOGGLE_DIALOG, payload: false }),
  setup: (user) => {
    return (dispatch, getState) => {
      const { user } = getState()['auth'];

      dispatch({ type: LOAD })

      return Auth.setupTOTP(user)
        .then((code) => dispatch({ type: SETUP, payload: code }))
    }
  },
  verify: (code) => {
    return (dispatch, getState) => {
      const { user } = getState()['auth'];

      return Auth.verifyTotpToken(user, code)
        .then(() => Auth.setPreferredMFA(user, 'TOTP'))
        .then(() => dispatch(login.refreshAuth()));
    }
  }
}
import { ACTIONS } from './../actions/table'

const initial = {
  rowsPerPage: 10
}

export default (state = initial, action) => {
  switch (action.type) {
    case ACTIONS.ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.rowsPerPage }
    default:
      return state;
  }
}

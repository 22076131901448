import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import { indexer, getFiltersFromState } from 'helpers/'

/**
 * List of action strings
 *
 * @type {object}
 */
export const ACTION = {
  GET_FIX_IT: 'get-fix-it'
}

/**
 * List of loading action strings
 *
 * @type {object}
 */
export const LOAD = {
  ...ACTION
}

/**
 * GraphQL queries
 */
export const queries = {
  getFixIt: `
    query ($start: Int, $fetch: Int) {
      getSuggestions (type: FIX_IT, start: $start, fetch: $fetch) {
        count
        data {
          id
          email
          created
          text
          type
        }
      }
    }
  `
}

export const getFixIt = () => {
  return loader.load(LOAD.GET_FIX_IT, (dispatch, getState) => {
    const filters = getFiltersFromState(getState())

    return API.graphql(graphqlOperation(queries.getFixIt, filters))
      .then((result) => dispatch({ type: ACTION.GET_FIX_IT, ...result.data.getSuggestions }))
  })
}

export const reducer = (state = {
  data: [],
  count: 0,
  byId: {}
}, action) => {
  switch (action.type) {
    case ACTION.GET_FIX_IT:
      return {
        ...state,
        count: action.count,
        data: action.data,
        byId: indexer(action.data, 'id')
      }
    default:
      return state
  }
}
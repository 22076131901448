import type { PaletteType, GridSpacing } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import util from 'util'
import color from 'color'
import './theme.d'

/**
 * Background colors. Grey/blues
 * in different tints, used for surfaces and borders
 */
const background = {
  // Material - UI overrides
  default: '#141d26',
  paper: '#1F2830',
  main: '#44505f',

  // Our theme values
  bg3: '#373F46',
  bg2: '#2A323A',
  bg1: '#1F2830',

  dark: '#36404c', /* '#1C2835' */
  light: '#62727b',
  darkOffset: 'rgba(0, 0, 0, 0.2)',
  lightOffset: 'rgba(255, 255, 255, 0.1)'
}

/**
 * Highlight colours
 */
const highlight = {
  border: '#62727b'
}

/**
 * Primary highlight colour.
 */
const primary = {
  main: '#00FFCD',
  light: '#DFEDEB',
  dark: '#9EC5C0'
}

/**
 * Secondary highlight colours
 */
const secondary = {
  main: '#80cdd9',
  light: '#6B9399',
  dark: '#36494D'
}

const error = {
  main: '#F84F5E',
  light: '#F97B86',
  dark: '#C53E4A'
}

const warning = {
  main: '#F99B27',
  dark: '#AD6B1B',
  light: '#FAB45D'
}

const info = {
  main: '#1481A6',
  dark: '#1DBAF0',
  light: '#56CBF3'
}

const success = {
  main: '#A7DC62',
  dark: '#749844',
  light: '#BDE489'
}

/**
 * Text tints
 */
const text = {
  primary: 'rgba(223, 237, 235, 0.9)',
  secondary: 'rgba(223, 237, 235, 0.6)',
  disabled: 'rgba(223, 237, 235, 0.3)'
}

const report = {
  in: {
    main: '#FF67B3'
  },
  out: {
    main: '#FF9898'
  },
  count: {
    main: '#3274a5'
  },
  spaces: {
    main: '#f78a2c',
    light1: '#FFD55E',
    light2: '#FAE6AE',
    dark1: '#EF5210',
    dark2: color('#EF5210').darken(0.3).string()
  },
  rooms: {
    main: '#f74d2c',
    light1: '#FF8250',
    light2: '#FFA76E',
    dark1: '#f0200e'
  },
  temp: {
    main: '#ffd069',
    comfortable: '#ffd069',
    cold: '#ccd6d6',
    cool: '#dbd4b6',
    warm: '#ff7d2b',
    hot: '#db4431'
  },
  sound: {
    main: '#218ebd',
    max: '#43d0ec',
    mean: '#1369bb'
  },
  humidity: {
    main: '#00aa8f'
  },
  co2: {
    main: '#9a3e5a',
    max: '#782349',
    maxLight: color('#782349').darken(0.2).string(),
    mean: '#c76d6f',
    meanLight: color('#c76d6f').darken(0.1).string(),
    meanThreshold: '#ff8282',
    maxThreshold: '#f3a9d2',
    co2UtilizationDot: '#ff8282',
    co2UtilizationDotRange: color('#ff8282').alpha(0.4).string(),
    co2UtilizationHighlight: '#c4aa41'
  },
  light: {
    main: '#fffcba',
    dark: '#50587a',
    dim: '#8b8b8b',
    good: '#f2d78e',
    veryBright: '#fdfddf',
    tooBright: '#fdfdf8'
  },
  tvoc: {
    main: '#6f46a7',
    dark1: color('#6f46a7').darken(0.2).string()
  },
  pm10: {
    main: '#a3e440'
  },
  pm4: {
    main: '#66ad0a'
  },
  pm25: {
    main: '#3f824a'
  },
  pm1: {
    main: '#06b897'
  },
  aqi: {
    main: '#06b897'
  },
  heartbeat: {
    main: '#1369bb'
  },
  warning: {
    concern: '#f5f553',
    bad: '#ff9225',
    severe: '#cf481c',
    extreme: '#c43049'
  }
}

const fontFamily = ['Montserrat', 'Arial', 'sans-serif'].join(',')

const typography = {
  // TODO go figure out rem values for accessibility
  h1: {
    fontSize: 97,
    fontWeight: 500
  },
  h2: {
    fontSize: 61,
    fontWeight: 500
  },
  h3: {
    fontSize: 48,
    fontWeight: 500
  },
  h4: {
    fontSize: 34,
    fontWeight: 500
  },
  h5: {
    fontSize: 24,
    fontWeight: 500
  },
  h6: {
    fontSize: 20,
    fontWeight: 500
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 400
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500
  },
  body1: {
    fontSize: 17,
    fontWeight: 400,
    fontFamily: 'Lato, Arial, sans-serif'
  },
  body2: {
    fontSize: 15,
    fontWeight: 400,
    fontFamily: 'Lato, Arial, sans-serif'
  },
  caption: {
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'Lato, Arial, sans-serif'
  },
  overline: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Lato, Arial, sans-serif'
  }
}

export const topBarPopoverOverride = {
  // Top-right user-context dropdown
  '& .MuiPaper-root': {
    backgroundColor: background.bg3,
    color: text.secondary,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  '& .MuiDivider-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
}

const TOOLBAR_HEIGHT = 92

export const theme = {
  // Custom properties //
  // ----------------- //
  drawerWidth: 304,
  toolbarHeight: TOOLBAR_HEIGHT,
  // ----------------- //
  props: {
    MuiDialog: {
      scroll: 'body' as const
    },
    MuiGrid: {
      spacing: 3 as GridSpacing
    },
    MuiPaper: {
      elevation: 1
    },
    MuiAppBar: {
      elevation: 1
    },
    MuiExpansionPanel: {
      square: true
    },
    MuiLink: {
      color: 'secondary' as const
    },
    MuiTooltip: {
      arrow: false,
      interactive: true,
      leaveDelay: 200
    },
    MuiButton: {
      disableElevation: true
    }
  },
  palette: {
    type: 'dark' as PaletteType,
    /**
     * Graph line colours, for dynamic
     * graph generation. Not the same as the "status" colours
     */
    graph: {
      workspaceUtilization: '#ff5e57',
      workspace_utilization: '#ff5e57',
      meetingUtilization: '#ff5e57',
      meeting_utilization: '#ff5e57',
      occupancy: '#ff5e57',
      mic: '#808e9b',
      lux: '#ffc048',
      temp: '#0be881',
      co2: '#4bcffa',
      tvoc: '#575fcf',
      humidity: '#d2dae2',
      battery: '#34e7e4',
      count: '#3274a5',
      max_count: '#b36764',
      conPm05: '#8CF9E0',
      conPm1: '#06B897',
      conPm25: '#3F824A',
      conPm4: '#83AD0A',
      conPm10: '#D0CD5F',
      massPm1: '#06B897',
      massPm25: '#3F824A',
      massPm4: '#83AD0A',
      massPm10: '#D0CD5F',
      heartbeat: '#D0CD5F',
      aqi: '#D0CD5F'
    },
    report: {
      ...report,
      // Alias keys
      workspaceUtilization: report.spaces,
      occupancy: report.spaces,
      meetingUtilization: report.rooms,
      lux: report.light,
      mic: report.sound,
      massPm1: report.pm1,
      massPm25: report.pm25,
      massPm4: report.pm4,
      massPm10: report.pm10
    },
    reports: {
      utilizationRates: {
        0: '#455cf6',
        1: '#1ecee9',
        2: '#bbe056',
        3: '#fd5454'
      },
      hourThresholds: {
        0: '#455cf6',
        1: '#1ecee9',
        2: '#bbe056',
        3: '#fd5454'
      },
      tvoc: {
        default: '#4C6164'
      },
      temp: {
        cold: '#aac0d7',
        cool: '#d7d292',
        comfortable: '#ffd069',
        warm: '#f88d54',
        hot: '#fc5868'
      },
      sound: {
        mean: '#6c966e',
        max: '#b0d9b2',
        meanAlternate: '#6c9693',
        maxLine: '#c4aa41'
      },
      pm: {
        10: '#d0cd5f',
        4: '#83ad0a',
        25: '#3f824a',
        1: '#06b897'
      },
      envRisk: {
        0: '#88A35F',
        1: '#f5f553',
        2: '#cf481c',
        3: '#C43049'
      },
      co2: {
        meanCo2: '#642732',
        meanCo2Stroke: '#ff3b61',
        meanCo2Threshold: '#ff8282',
        maxCo2: '#3c2d41',
        maxCo2Threshold: '#f3a9d2',
        co2UtilizationDot: '#ff8282',
        co2UtilizationDotRange: color('#ff8282').alpha(0.4).string(),
        co2UtilizationHighlight: '#c4aa41'
      },
      light: {
        dark: '#50587a',
        dim: '#8b8b8b',
        good: '#f2d78e',
        bright: '#fffcba',
        blinding: '#fdfdf8'
      }
    },
    pie: [
      primary.main,
      secondary.main,
      '#ff5e57',
      '#34e7e4',
      '#808e9b',
      '#d2dae2'
    ],
    // @see https://learnui.design/tools/data-color-picker.html#single
    heatmap: {
      '0.0': '#ffdcc0',
      '0.2': '#fac99f',
      '0.4': '#f3b67e',
      '0.6': '#eba35e',
      '0.8': '#e1903c',
      '1.0': '#d67e11'
    },
    heatmapBat: {
      '0.0': '#d80f16',
      '0.2': '#f3531e',
      '0.4': '#f7bd3f',
      '0.6': '#cfe367',
      '0.8': '#9aea75',
      '1.0': '#74f8a4'
    },
    /**
     * Background tints
     */
    background: {
      ...background,
      offset: background.darkOffset,
      contrastText: 'rgb(255, 255, 255)'
    },
    primary: primary,
    secondary: secondary,
    disabled: text.disabled,
    /**
     * Primary <Divider/> colour. Used on other divider borders
     */
    divider: background.lightOffset,
    error,
    warning,
    info,
    success,
    /**
     * Application text tints
     */
    text: text,
    /**
     * The status colours are used for "ok", "warning", "error" indidcators
     * on graphs or other data
     */
    status: {
      error: error.main,
      warning: secondary.main,
      ok: primary.main
    }
  },
  typography: {
    fontFamily: fontFamily
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        code: {
          color: secondary.main
        }
      }
    },
    MuiTypography: typography,
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiAlert: {
      root: {
        border: 0
      },
      standardWarning: {
        color: warning.light,
        backgroundColor: color(warning.main).alpha(0.12).string(),
        '& .MuiAlert-icon': {
          color: warning.main
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        fontSize: 12,
        padding: 12
      }
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: background.lightOffset
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: primary.dark,
        color: primary.light
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: background.bg2
      }
    },
    MuiTableCell: {
      root: {
        borderColor: util.format('%s !important', background.lightOffset),
        fontFamily: 'Lato, Arial, sans-serif'
      },
      head: {
        border: 'none',
        paddingTop: 10,
        paddingBottom: 10,
        color: text.secondary
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Lato, Arial, sans-serif',
        fontSize: 15,
        fontWeight: 700
      },
      containedPrimary: {
        color: 'rgba(0, 0, 0, 0.8)'
      }
    },
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: background.darkOffset,
          color: secondary.main
        }
      }
    },
    MuiIconButton: {
      root: {
        color: background.light
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: background.darkOffset
      }
    },
    MuiChip: {
      root: {
        backgroundColor: background.darkOffset
      }
    },
    // Tabs should have a blue colour by default
    // and not dim if not selected.
    MuiTab: {
      root: {
        minWidth: 'auto !important',
        '@media (min-width: 960px)': {
          fontSize: '0.875rem'
        }
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: `1px solid ${highlight.border}`
      },
      paperAnchorRight: {
        borderLeft: `1px solid ${highlight.border}`
      }
    },
    // Tab indicator. Our theme increases
    // the size.
    MuiTabIndicator: {
      root: {
        height: 4
      }
    },
    MuiDialogActions: {
      root: {
        padding: '0 18px 18px'
      }
    },
    MuiSelect: {
      icon: {
        color: 'inherit',
        right: 5
      }
    },
    MuiInputAdornment: {
      root: {
        color: text.secondary
      }
    },
    MuiInput: {
      root: {
        color: text.primary
      },
      underline: {
        '&:before': {
          borderColor: util.format('%s !important', background.main)
        },
        '&:after': {
          borderColor: util.format('%s !important', secondary.main)
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: background.darkOffset,
        borderRadius: 0
      },
      input: {
        padding: 12,
        // Autofill colour
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 100px ${background.light} inset`
        }
      }
    },
    MuiTextField: {
      root: {
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 14px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
          }
        }
      }
    },
    MuiPopover: {
      paper: {
        backgroundColor: background.bg3,
        border: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }
    },
    MuiAutocomplete: {
      root: {
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 14px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
          }
        }
      },
      paper: {
        backgroundColor: background.light,
        border: 0
      },
      inputRoot: {
        '&.MuiOutlinedInput-root': {
          padding: 2,
          paddingRight: 8,
          paddingLeft: 8
        }
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          background: 'transparent',
          '& .MuiListItemText-root': {
            color: primary.main
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
        color: text.primary
      }
    },
    MuiToolbar: {
      regular: {
        // Set toolbar fixed height so it's always the same on mobile and desktop
        height: TOOLBAR_HEIGHT
      }
    },
    /**
     * Custom styles for application AppBar. This is because when we use the "dark" theme, the
     * appbar is actually a light colour. Instead of creating a whole new "white" theme, we just
     * manipulate the main theme.
     */
    MuiAppBar: {
      colorDefault: {
        border: 'none !important',
        color: text.primary,
        backgroundColor: background.bg2,
        '& .MuiToggleButton-root': {
          color: text.primary,
          paddingLeft: 8,
          paddingRight: 8,
          '&.Mui-selected': {
            color: primary.main,
            backgroundColor: background.bg3,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.15)'
            }
          },
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          }
        },
        '& .MuiSelect-root.MuiInputBase-input.Mui-disabled': {
          color: text.disabled
        },
        ...topBarPopoverOverride,
        '& .MuiFormLabel-root': {
          color: text.primary,
          padding: 16,
          textTransform: 'uppercase',
          ...typography.overline,
          fontSize: 14,
          '&.Mui-focused': {
            color: primary.main
          },
          '&.Mui-disabled': {
            color: text.disabled
          }
        },
        '& .MuiListItem-button': {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          }
        },
        '& .MuiListItemIcon-root': {
          color: text.primary
        },
        '& .MuiFilledInput-root': {
          position: 'relative',
          borderRadius: 0,
          backgroundColor: 'transparent',
          color: text.primary,
          height: TOOLBAR_HEIGHT,
          '& .MuiSelect-iconFilled': {
            top: 'calc(50% - 0px)'
          },
          '&.Mui-focused': {
            backgroundColor: `${background.bg3} !important`
          }
        },
        '& .MuiFilledInput-underline:before': {
          borderColor: 'transparent'
        },
        '& .MuiFilledInput-input': {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: 24,
          paddingTop: 48,
          boxSizing: 'border-box',
          ...typography.h6,
          '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }
        },
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.87)',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${background.darkOffset}`
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${primary.main}`
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${background.darkOffset}`
        }
      }
    }
  }
}

/**
 * Create a new default theme
 */
export default createMuiTheme(theme)

import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import dayjs from 'dayjs'
import error from './error'
import * as queries from './../gql/queries'
import * as mutations from './../gql/mutations'
import { I18n } from '@aws-amplify/core'
import notification from './notification'
import axios from 'axios'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/**
 * Available action types
 */
export const ACTIONS = {
  REMOVE_DEVICE: 'ws-remove-device',
  // TODO should probably not be here
  UPDATE_SENSOR: 'workspace-update-sensor',
  SELECT_DESK: 'workspace-select-desk',
  SELECT_BUILDING: 'workspace-select-building',
  SELECT_FLOOR: 'workspace-select-floor',
  SELECT_AREA: 'workspace-select-area',
  LOAD_WORKSPACES: 'workspace-load-workspaces',
  UPDATE_DESK: 'workspace-save-desk',
  OVERLAY_SET_DATE: 'workspace-overlay-set-date',
  OVERLAY_SET_ACTIVE: 'workspace-overlay-set-active',
  OVERLAY_GET_DATA: 'workspace-overlay-get-data',

  SAVE_WORKSPACE_BUILDING: 'save-workspace-building',
  ADD_WORKSPACE_BUILDING: 'add-workspace-building',
  UPDATE_WORKSPACE_FLOOR: 'update-workspace-floor',
  ADD_WORKSPACE_FLOOR: 'add-workspace-floor',
  UPDATE_WORKSPACE_AREA: 'update-workspace-area',
  ADD_WORKSPACE_AREA: 'add-workspace-area',

  BUMP_COMPANY_CONTENT_KEY: 'bump-company-content-key',

  LOAD_GEO: 'workspace-load-geo',
  LOAD: 'workspace-load',
  LOADING: 'workspace-loading',
  LOAD_SENSOR_DATA: 'workspace-load-sensor-data',

  GET_BUILDINGS_DATA: 'workspace-get-buildings',
  SET_BUILDINGS_FILTERS: 'set-filters-workspace-buildings',
  SET_BUILDINGS_PAGE: 'set-page-workspace-buildings'
}

export const ACTION = ACTIONS

/**
 * Workspace "loading" keys
 */
export const LOAD = {
  WORKSPACES: 'ws-workspaces',
  UPDATE_DESK: 'ws-update-desk',
  GEOJSON: 'ws-geojson',
  DESK_DATA: 'ws-desk-data',
  SAVE_WORKSPACE_BUILDING: 'save-workspace-building',
  UPDATE_WORKSPACE_FLOOR: 'update-workspace-floor',
  UPDATE_WORKSPACE_AREA: 'update-workspace-area',
  UPLOAD_FLOOR_IMAGE: 'upload-floor-image',
  ADD_WORKSPACE_BUILDING: 'add-workspace-building',
  ADD_WORKSPACE_FLOOR: 'add-workspace-floor',
  ADD_WORKSPACE_AREA: 'add-workspace-area',
  LOAD_BUILDINGS: 'load-buildings',
  LINK_DEVICE: 'ws-link-device',
  ...ACTIONS
}

export const removeDevice = (desk, id) => {
  return loader.load(LOAD.REMOVE_DEVICE, (dispatch, getState) => {
    return API.graphql(graphqlOperation(mutations.updateSensor, { id, reportInto: null }))
      .then((result) => {
        dispatch({ type: ACTIONS.REMOVE_DEVICE, desk, id })
      })
  })
}

/**
 * Query all workspace dependencies. Buildings/floors and areas.
 *
 * @type {string}
 */
const getWorkspacesQuery = `
  query {
    buildings: getBuildings {
      id
      name
      address
      archive
    }

    floors: getFloors {
      id
      name
      level
      building
      archive
    }

    areas: getAreas {
      id
      name
      building: buildingId
      floor: floorId
      capacity
      archive
    }
  }
`

/**
 * Update an existing floor
 *
 * @type {string}
 */
const updateFloorMutation = `
  mutation ($id: Int!, $name: String!, $archive: Boolean, $level: Int) {
    updateFloor(id: $id, name: $name, archive: $archive, level: $level) {
      id
      name
      level
      building
      archive
    }
  }
`

export default {
  /**
   * Link a new device against the current selected desk
   */
  linkDevice: (id) => {
    return loader.load(LOAD.LINK_DEVICE, (dispatch, getState) => {
      const { _workspace } = getState()
      let { desk } = _workspace.selected

      // Convert hex into decimal
      id = /^\d+$/.test(id) ? id : parseInt(id, 16)

      return API.graphql(graphqlOperation(mutations.updateSensor, { id:id , reportInto: desk }))
        .then((result) => {
          dispatch({ type: ACTIONS.UPDATE_SENSOR, desk, data: result.data.updateSensor })
        })
    })
  },
  clearOverlayData: () => ({ type: ACTIONS.OVERLAY_GET_DATA, data: null }),
  getOverlayData: () => {
    return loader.load(LOAD.GEOJSON, (dispatch, getState) => {
      const { workspace, _workspace } = getState()
      let { building } = _workspace.selected
      const { from, to } = workspace.overlay

      return API.graphql(graphqlOperation(queries.getMeasurementsMeanGrouped, { from: from.unix(), to: to.unix(), building, grouped: 'desk' }))
        .then((result) => {
          dispatch({ type: ACTIONS.OVERLAY_GET_DATA, data: result.data.getMeasurementsMeanGrouped })
        })
    })
  },
  setOverlayDate: (from, to) => ({ type: ACTIONS.OVERLAY_SET_DATE, from, to }),
  setActiveOverlay: (active) => ({ type: ACTIONS.OVERLAY_SET_ACTIVE, active }),
  /**
   * Raise alert, image could not load properly
   *
   * @return {function}
   */
  imageError: () => {
    return (dispatch) => dispatch(error.error(I18n.get('error:floorplan')))
  },
  /**
   * Update a desk
   *
   * @param {object} properties - The object properties
   *
   * @return {Promise}
   */
  updateDesk: (properties) => {
    return loader.load(LOAD.UPDATE_DESK, (dispatch) => {
      return API.graphql(graphqlOperation(mutations.updateDesk, properties))
        .then((res) => dispatch({ type: ACTIONS.UPDATE_DESK, desk: res.data.updateDesk }))
    })
  },
  /**
   * Select a sensor and mark it as active
   *
   * @param {number} desk - The desk
   *
   * @return {object}
   */
  selectDesk: (desk) => {
    return ({ type: ACTIONS.SELECT_DESK, desk })
  },
  /**
   * Load data for a specific desk
   */
  loadDeskData: () => {
    return loader.load(LOAD.DESK_DATA, (dispatch, getState) => {
      const { desk } = getState()['workspace'].selected
      const from = dayjs().subtract('24', 'hours').unix()
      const to = dayjs().unix()

      return API.graphql(graphqlOperation(queries.loadDeskData, { desk: desk.toString(), from, to, fill: false }))
        .then((result) => dispatch({ type: ACTIONS.LOAD_SENSOR_DATA, desk: desk, sensors: result.data.getSensorsByDesk, data: result.data.getMeasurements }))
    })
  }
}

export const bumpCompanyContentKey = () => {
  return ({ type: ACTIONS.BUMP_COMPANY_CONTENT_KEY })
}

/**
 * Buld load entire company workspace hierarchy
 */
export const loadWorkspaces = () => {
  return loader.load(LOAD.WORKSPACES, (dispatch) => {
    return API.graphql(graphqlOperation(getWorkspacesQuery))
      .then((res) => dispatch({ type: ACTIONS.LOAD_WORKSPACES, ...res.data }))
  })
}

export const saveBuilding = (values) => {
  return loader.load(LOAD.SAVE_WORKSPACE_BUILDING, (dispatch) => {
    // If the ID exists, run an update
    if (values.id) {
      return API.graphql(graphqlOperation(mutations.updateBuilding, { id: values.id, name: values.name, address: values.address, archive: values.archive }))
        .then((result) => dispatch({ type: ACTIONS.SAVE_WORKSPACE_BUILDING, data: result.data.updateBuilding }))
        .then(() => dispatch(notification.success('Building details successfully updated')))
        .catch((err) => dispatch(notification.error(err.message)))
    } else {
      return Promise.resolve(true)
    }
  })
}

export const createBuilding = (values) => {
  return loader.load(LOAD.ADD_WORKSPACE_BUILDING, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.createBuilding, { name: values.name, address: values.address }))
      .then((result) => dispatch({ type: ACTIONS.ADD_WORKSPACE_BUILDING, data: result.data.createBuilding }))
      .then(() => dispatch(notification.success('Building successfully created')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

export const updateFloor = (values) => {
  const { id, name, archive, level } = values

  return loader.load(LOAD.UPDATE_WORKSPACE_FLOOR, (dispatch) => {
    return API.graphql(graphqlOperation(updateFloorMutation, { id, name, archive, level }))
      .then((result) => dispatch({ type: ACTIONS.UPDATE_WORKSPACE_FLOOR, data: result.data.updateFloor }))
      .then(() => dispatch(notification.success('Floor details successfully updated')))
      .catch((err) => dispatch(error.gql(err)))
  })
}

/**
 * Create a new workspace floor.
 *
 * @param {object} values - Workspace floor values
 *
 * @return Promise
 */
export const createFloor = (values) => {
  const { name, building } = values

  return loader.load(LOAD.ADD_WORKSPACE_FLOOR, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.createFloor, { name, building }))
      .then((result) => dispatch({ type: ACTIONS.ADD_WORKSPACE_FLOOR, data: result.data.createFloor }))
      .then(() => dispatch(notification.success('Floor created successfully')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

export const updateArea = (values) => {
  const { id, name, archive } = values

  return loader.load(LOAD.UPDATE_WORKSPACE_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.updateArea, { id, name, archive }))
      .then((result) => dispatch({ type: ACTIONS.UPDATE_WORKSPACE_AREA, data: result.data.updateArea }))
      .then(() => dispatch(notification.success('Area details successfully updated')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

export const createArea = (values) => {
  return loader.load(LOAD.ADD_WORKSPACE_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.createArea, { name: values.name, floor: values.floor }))
      .then((result) => dispatch({ type: ACTIONS.ADD_WORKSPACE_AREA, data: result.data.createArea }))
      .then(() => dispatch(notification.success('Area successfully created')))
      .catch((err) => dispatch(notification.error(err.message)))
  })
}

/**
 * Upload floor image. Accepts browser File() as image
 *
 * @param {File} file - The file to upload
 */
export const uploadFloorImage = (id, file) => {
  return loader.load(LOAD.UPLOAD_FLOOR_IMAGE, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getFloorUploadUrl, { id }))
      .then((result) => result.data.getFloorUploadUrl.url)
      // Run the upload to signed URL generated
      .then((url) => axios.put(url, file, { headers: { 'Content-Type': file.type } }))
      .then((res) => (res.status === 200))
      .then(() => dispatch(bumpCompanyContentKey()))
  })
}

/**
 * Select a specific area
 *
 * @param {number} area - The area
 *
 * @return {object}
 */
export const selectArea = (area) => ({ type: ACTIONS.SELECT_AREA, area })

/**
 * Select a specific floor
 *
 * @param {number} floor - The floor
 *
 * @return {object}
 */
export const selectFloor = (floor) => ({ type: ACTIONS.SELECT_FLOOR, floor })

/**
 * Select a new building as the active filter
 *
 * @param {number} building - The selected building
 *
 * @return {object}
 */
export const selectBuilding = (building) => ({ type: ACTIONS.SELECT_BUILDING, building })



const persistedInitial = {
  selected: { building: null, floor: null, area: null }
}

export const persistedReducer = persistReducer({ key: '_workspace', storage }, (state = persistedInitial, action) => {
    switch (action.type) {
      /**
       * When select a new area
       */
      case ACTION.SELECT_AREA:
        return { ...state, selected: { ...state.selected, area: action.area } }
      /**
       * When select a new floor, clear all other selections
       */
      case ACTION.SELECT_FLOOR:
        return { ...state, selected: { ...state.selected, floor: action.floor, area: null } }
      /**
       * Selecting a new building should reset all other filters
       */
      case ACTION.SELECT_BUILDING:
        return { ...state, selected: { building: action.building, floor: null, area: null } }
      default:
        return state
    }
})

import API, { graphqlOperation } from '@aws-amplify/api'
import loader from '@ergosense/ergosense-react-common/lib/actions/loader'
import { signCompanyContent } from '@ergosense/ergosense-react-common/lib/actions/signed'
import { createCompanyContentUrl } from '@ergosense/ergosense-react-common/lib/helpers/'
import dayjs from 'dayjs'
import axios from 'axios'
import { getActiveCompanyId } from 'helpers/'
import error from './error'

export const ACTION = {
  CLEAR: 'viewer-clear',
  LOAD_SPACE: 'viewer-load-space',
  LOAD_NO_SPACE: 'viewer-load-no-space',
  LOAD_DESK_DATA: 'load-desk-data',
  CLEAR_DESK_DATA: 'clear-desk-data',
  UPDATE_SENSOR: 'update-device',
  REMOVE_DEVICE: 'viewer-remove-device',
  SET_OVERLAY_PARAM: 'viewer-set-overlay-param',
  OVERLAY_GET_DATA: 'viewer-set-overlay-data'
}

export const LOAD = ACTION

/**
 * Load desk data based on ID or UUID
 *
 * @type {string}
 */
export const loadDeskDataQuery = `
  query ($id: String!) {
    getDesk(id: $id) {
      id
      area
      uuid
      capacity
      lastEvent
    }
  }
`

/**
 * Standalone sensor lookup for the floor. Will be used
 * to include information such as "online" status
 */
export const getSensorsQuery = `
  query ($floor: Int!, $fetch: Int!) {
    getSensors (floorId: [$floor], fetch: $fetch) {
      data {
        id
        lastEvent
        type
        reportInto
        reportIntoUUID
        connected
      }
    }
  }
`

/**
 * Load desk data such as the sensors and latest
 * measurements for the specific desk
 *
 * @type {string}
 */
export const loadDeskDataDetailQuery = `
  query ($id: String!, $deskId: Int!) {
    getSensorsByDesk(desk: $id) {
      id,
      lastEvent,
      type,
      reportInto,
      firmware,
      identity
    },

    getLastMeasurements(deskId: $deskId) {
      battery
      co2
      humidity
      lux
      mic
      occupancy
      temp
      tvoc
      in
      out
    }
  }
`

/**
 * Lookup measurements grouped by a specific
 * dimension
 *
 * @type {string}
 */
export const getMeasurementsMeanGroupedQuery = `
  query ($from: Int!, $to: Int!, $building: Int, $floor: Int, $area: Int, $grouped: String!) {
    getDesks: getDesksV2(floor: $floor) {
      id
      uuid
    }

    getMeasurementsMeanGrouped(from: $from, to: $to, building: $building, floor: $floor, area: $area, grouped: $grouped) {
      battery,
      co2,
      humidity,
      lux,
      mic,
      workspace_utilization,
      meeting_utilization,
      temp,
      tvoc,
      count,
      desk,
      capacity
    }
  }
`

export const getOverlayData = () => {
  return loader.load(LOAD.OVERLAY_GET_DATA, (dispatch, getState) => {
    const state = getState()
    const { floor } = state._workspace.selected
    const { from, to } = state.workspaceViewer.overlay

    return API.graphql(graphqlOperation(getMeasurementsMeanGroupedQuery, { from: from.unix(), to: to.unix(), floor, grouped: 'desk' }))
      .then((result) => {
        // Create map between desk ID and desk UUID
        const deskData = result.data.getDesks.reduce((carry, current) => ({ ...carry, [current.id]: current.uuid }), {})

        // Index desk data based on desk UUID
        const indexed = result.data.getMeasurementsMeanGrouped.reduce((carry, current) => {
          const uuid = deskData[current.desk]
          return uuid ? { ...carry, [uuid]: current } : carry
        }, {})

        dispatch({ type: ACTION.OVERLAY_GET_DATA, data: indexed })
      })
  })
}

export const setOverlayParam = (from, to, key) => {
  return (dispatch, getState) => {
    const overlay = getState()['workspaceViewer'].overlay

    // Check if we should request new data
    const refresh = !overlay || !overlay.key || !overlay.from || !overlay.from.isSame(from) || !overlay.to || !overlay.to.isSame(to)

    dispatch({ type: ACTION.SET_OVERLAY_PARAM, from, to, key })

    return (refresh ? dispatch(getOverlayData()) : null)
  }
}

export const removeDevice = (id) => {
  return loader.load(LOAD.REMOVE_DEVICE, (dispatch, getState) => {
    const mutation = `
      mutation ($id: ID!, $reportInto: Int) {
        updateSensor (id: $id, reportInto: $reportInto) {
          id
          lastEvent
          type
          reportInto
          reportIntoUUID
          connected
        }
      }
    `

    return API.graphql(graphqlOperation(mutation, { id, reportInto: null }))
      .then((result) => {
        dispatch({ type: ACTION.REMOVE_DEVICE, id })
      })
  })
}

export const linkDevice = (desk, id, force) => {
  return loader.load(LOAD.UPDATE_SENSOR, (dispatch, getState) => {
    // Convert hex into decimal
    id = parseInt(id, 16)

    const mutation = `
      mutation ($id: ID!, $reportInto: Int, $forceUpdate: Boolean) {
        updateSensor (id: $id, reportInto: $reportInto, forceUpdate: $forceUpdate) {
          id
          lastEvent
          type
          reportInto
          reportIntoUUID
          connected
        }
      }
    `

    let filters = { id: id , reportInto: desk }

    // Force update when specified
    if (force) filters = { ...filters, forceUpdate: force }

    return API.graphql(graphqlOperation(mutation, filters))
      .then((result) => {
        dispatch({ type: ACTION.UPDATE_SENSOR, desk, data: result.data.updateSensor })

        return result.data.updateSensor
      })
      .catch((err) => {
        dispatch(error.gql(err))
      })
  })
}

export const loadDeskData = (id) => {
  return loader.load(LOAD.LOAD_DESK_DATA, async (dispatch) => {
    dispatch({ type: ACTION.CLEAR_DESK_DATA })

    const result = await API.graphql(graphqlOperation(loadDeskDataQuery, { id }))

    // Sensors/measurements are linked against the ID of a desk and not UUID
    const detail = await API.graphql(graphqlOperation(loadDeskDataDetailQuery, { id: result.data.getDesk.id, deskId: result.data.getDesk.id }))

    return dispatch({
      type: ACTION.LOAD_DESK_DATA,
      desk: result.data.getDesk,
      measurements: detail.data.getLastMeasurements,
      sensors: detail.data.getSensorsByDesk
    })
  })
}

export const loadSpace = (floorId) => {
  return loader.load(LOAD.LOAD_SPACE, async (dispatch, getState) => {
    // Clear existing data
    dispatch({ type: ACTION.CLEAR })

    // No floor, nothing more to do
    if (!floorId) return Promise.resolve()

    // Grab the active company ID
    const state = getState()
    const companyId = getActiveCompanyId(state)

    const signed = await dispatch(signCompanyContent())

    // Create a signed company content URL which refers to the floors
    // geojson file
    // TODO have a cache key, right now it's not caching...we need a cache key
    // and a way to force invalidation.
    const url = createCompanyContentUrl(companyId, `${floorId}.geojson`, { versionKey: dayjs().unix(), ...signed })

    // Query the floor geojson and desk data at the same time
    try{
      const content = await axios.get(url)

      // TODO calculate the amount to fetch, do this based on active
      // desks or something...should not be hardcoded
      const sensors = await API.graphql(graphqlOperation(getSensorsQuery, { floor: floorId, fetch: 500  }))

      // Loop through all features and embed sensor status properties
      const geojson = {
        ...content.data,
        features: (content.data.features || [])
      }

      dispatch({ type: ACTION.LOAD_SPACE, geojson, sensors: sensors.data.getSensors.data })

    } catch (e) {
      // "403" error usually means the file does not exist
      // TODO use a 403 constant from ergosense-lambda or something
      if (e.response && e.response.status === 403) {
        dispatch({ type: ACTION.LOAD_NO_SPACE })
      } else {
        // Forward exception, still valid error
        throw e
      }
    }
  })
}

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION.OVERLAY_GET_DATA:
      return {
        ...state,
        overlayData: action.data
      }
    case ACTION.SET_OVERLAY_PARAM:
      return {
        ...state,
        overlay: {
          from: action.from,
          to: action.to,
          key: action.key
        }
      }
    case ACTION.REMOVE_DEVICE:
      return {
        ...state,
        sensors: (state.sensors || []).filter((i) => i.id !== action.id),
        deskData: {
          ...state.deskData,
          sensors: (state.deskData.sensors || []).filter((i) => i.id !== action.id)
        }
      }
    case ACTION.UPDATE_SENSOR:
      // Current desk sensors
      const sensors = (state.deskData.sensors || []).concat(action.data)
      // Remove duplicates
      const deduplicate = (item, index) => sensors.findIndex((i) => item.id === i.id) === index

      // TODO don't have desk sensor data

      // Main sensor list
      const mainSensors = (state.sensors || []).concat(action.data)

      // Remove duplicates
      const mainDeduplicate = (item, index) => mainSensors.findIndex((i) => item.id === i.id) === index

      // Return mutated state
      return {
        ...state,
        sensors: mainSensors.filter(mainDeduplicate),
        deskData: {
          ...state.deskData,
          sensors: sensors.filter(deduplicate)
        }
      }
    case ACTION.CLEAR_DESK_DATA:
      return {
        ...state,
        deskData: null
      }
    case ACTION.LOAD_DESK_DATA:
      return {
        ...state,
        deskData: {
          desk: action.desk,
          measurements: action.measurements,
          sensors: action.sensors
        }
      }
    case ACTION.CLEAR:
      return {}
    case ACTION.LOAD_SPACE:
      return {
        ...state,
        geojson: action.geojson,
        sensors: action.sensors
      }
    default:
      return state
  }
}

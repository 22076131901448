import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import reducers from './../reducers'
import logger from 'redux-logger'
import config from './config'
import { persistStore } from 'redux-persist'

/*
 | Route History
 | -------------
 | Create a browser history stack. This keeps track
 | of user movement through the application.
 */
const history = createBrowserHistory()

export { history }

// Middleware provide additional features to our reducers
const middleware = []

/*
 | Router
 | ------
 | The router middleware will add route state
 | to our application. This will help us navigate using things like history.push()
 */
middleware.push(routerMiddleware(history))

/*
 | Thunk
 | -----
 | Thunk middleware allows dispatching to happen on asynchronous functions.
 | @see https://github.com/reduxjs/redux-thunk
 */
middleware.push(thunk)

/*
 | Redux logger
 | ------------
 | Redux debug. Keeps track of state changes.
 | @see https://github.com/evgenyrodionov/redux-logger
 */
config.debug && middleware.push(logger)

/*
 | Redux Dev tools
 | ---------------
 | DevTools for Redux with actions history, undo and replay.
 | https://github.com/zalmoxisus/redux-devtools-extension
 */
const composeEnhancers = (config.debug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 15 })) || compose

const store = createStore(
  reducers(history),
  composeEnhancers(
    applyMiddleware(...middleware)
  )
)

export default store

export const persistor = persistStore(store)

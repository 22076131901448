import API, { graphqlOperation } from '@aws-amplify/api'
import loader from '@ergosense/ergosense-react-common/lib/actions/loader'
import { signCompanyContent, uploadCompanyContent } from '@ergosense/ergosense-react-common/lib/actions/signed'
import { createCompanyContentUrl } from '@ergosense/ergosense-react-common/lib/helpers/'
import axios from 'axios'
import dayjs from 'dayjs'
import { getActiveCompanyId } from 'helpers/'
import bbox from '@turf/bbox'

export const ACTION = {
  LOAD_STATE: 'editor-load-state',
  OVERLAY: 'editor-overlay',
  SETTINGS: 'editor-settings',
  OVERLAY_MASK: 'editor-overlay-mask',
  SAVE_STATE: 'editor-save-state',
  SET_JSON: 'editor-set-json',
  UNDO: 'editor-undo',
  EDITOR_ERROR: 'editor-error',
  FLOOR_INFO: 'editor-floor-info',
  SET_SATELITE: 'editor-set-satelite'
}

/**
 * Query floor information
 *
 * @type {string}
 */
export const getFloorQuery = `
  query($id: Int!) {
    getFloor(id: $id) {
      id,
      name
    }
  }
`

export const LOAD = ACTION

export const loadState = (floorId) => {
  return loader.load(LOAD.LOAD_STATE, async (dispatch, getState) => {
    // Grab the active company ID
    const state = getState()
    const companyId = getActiveCompanyId(state)

    try {
      const floorResult = await API.graphql(graphqlOperation(getFloorQuery, { id: floorId }))
      dispatch({ type: ACTION.FLOOR_INFO, info: floorResult.data.getFloor })
    } catch (e) {
      // Stop editor, might be invalid floor
      return dispatch({ type: ACTION.EDITOR_ERROR, error: e.message })
    }

    // Sign company content, the floor exists
    const signed = await dispatch(signCompanyContent())

    // Create a signed company content URL which refers to the floors
    // geojson file
    const url = createCompanyContentUrl(companyId, `${floorId}.geojson`, { versionKey: dayjs().unix(), ...signed })

    try {
      const content = await axios.get(url)
      dispatch({ type: ACTION.LOAD_STATE, geojson: content.data })
    } catch (e) {
      // Floor plan does not exist yet, not an error
      if (e.response && e.response.status === 403) {
        dispatch({ type: ACTION.LOAD_STATE, geojson: null })
      } else {
        throw e
      }
    }
  })
}

export const saveState = (floorId) => {
  return loader.load(LOAD.SAVE_STATE, (dispatch, getState) => {
    const data = getState()['editor']

    return dispatch(uploadCompanyContent('application/json', {
      type: 'FeatureCollection',
      overlay: data.overlay,
      overlayMask: data.overlayMask,
      settings: data.settings,
      features: data.features,
      bbox: data.bbox
    }, `${floorId}.geojson`))
  })
}

export const setSatelite = (satelite) => ({ type: ACTION.SET_SATELITE, satelite })

export const setJson = (json) => ({ type: ACTION.SET_JSON, json })

export const setOverlay = (overlay) => ({ type: ACTION.OVERLAY, overlay })

export const setSettings = (obj) => ({ type: ACTION.SETTINGS, settings: obj })

export const setOverlayMask = (id) => ({ type: ACTION.OVERLAY_MASK, id })

export const undo = () => ({ type: ACTION.UNDO })

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION.SET_SATELITE:
      return {
        ...state,
        satelite: action.satelite
      }
    case ACTION.FLOOR_INFO:
      return {
        ...state,
        floorName: action.info && action.info.name
      }
    case ACTION.EDITOR_ERROR:
      return {
        ...state,
        error: true
      }
    case ACTION.UNDO:
      return {
        ...state,
        features: state.oldFeatures || [],
        oldFeatures: []
      }
    case ACTION.SET_JSON:
      return {
        ...state,
        ...action.json,
        bbox: bbox(action.json),
        oldFeatures: (state.features || [])
      }
    case ACTION.OVERLAY:
      return { ...state, overlay: action.overlay }
    case ACTION.SETTINGS:
      return { ...state, settings: { ...state.settings, ...action.settings } }
    case ACTION.OVERLAY_MASK:
      return { ...state, overlayMask: action.id }
    case ACTION.LOAD_STATE:
      return {
        ...state,
        ...action.geojson,
        oldFeatures: []
      }
    default:
      return state
  }
}

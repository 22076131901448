import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  background: {
    // noop
  },
  layout: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  layoutBreak: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  container: {
    textAlign: 'center'
  }
});

/**
 * This layout has no real structure, it's a grey-ish background with a fading
 * animation and a container div.
 */
const Blank = ({ classes, children, breakpoint }) => {
  return (
    <React.Fragment>
      <Box width="100%" height="100%" className={classes.background}>
        <main className={(breakpoint ? classes.layoutBreak : classes.layout)}>
          <div className={classes.container}>
            {children}
          </div>
        </main>
      </Box>
    </React.Fragment>
  );
};

Blank.propTypes = {
  /**
   * If you specify breakpoint=yes, the container div will shrink to a specified size. This
   * is used for pages like login, where the login box is small and contained in the middle of
   * the screen.
   */
  breakpoint: PropTypes.bool,
  /**
   * Class overrides
   */
  classes: PropTypes.object.isRequired,
  /**
   * Child content, React element or array of elements
   */
  children: PropTypes.any.isRequired
};

export default withStyles(styles)(Blank);
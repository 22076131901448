import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import BlankLayout from './../../layout/blank'
import { I18n } from '@aws-amplify/core'

/**
 * Renders an error page based on a caught exception
 */
export const ErrorGate = ({ trace, error }) => {
  return (
    <BlankLayout>
      <br/><br/>

      <Typography variant="h2" gutterBottom={true}>
        {I18n.get('error:title')}
      </Typography>

      <Typography variant="body1" paragraph={true}>{I18n.get('error:message')}</Typography>

      <Button onClick={() => window.location.reload()} variant="contained" color="primary">{I18n.get('error:reload')}</Button>

      <br/><br/>

      {trace &&
        <Paper>
          <Box p={2}>
            <pre>
              <code>{error.message}</code>
              <br/>
              <code>{error.stack}</code>
            </pre>
          </Box>
        </Paper>
      }
    </BlankLayout>
  )
}

ErrorGate.defaultProps = {
  trace: false
}

ErrorGate.propTypes = {
  /**
   * Display extra debug in development environments
   */
  trace: PropTypes.bool,
  /**
   * The error object to render
   */
  error: PropTypes.object.isRequired
}

/**
 * This component serves as an error boundary and catches all
 * errors in child components.
 */
class ErrorGateBoundary extends Component {
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, info) {
    // noop
  }

  render() {
    const { children, trace } = this.props
    const { error } = this.state

    if (error) {
      return <ErrorGate trace={trace} error={error} />
    }

    return children
  }
}

ErrorGateBoundary.defaultProps = {
  trace: false
}

ErrorGateBoundary.propTypes = {
  trace: PropTypes.bool
}

export default ErrorGateBoundary

import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { default as store } from './store'

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
})

API.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => ({
    /**
     * According to amplify developers, by default they will always send access token
     * as that is best practice. Since we are using mapping templates with AWS AppSync, we need
     * access to the user claims to see if the user has company access. In future, we should consider
     * using a pipeline resolver on AppSync to determine the users companies.
     *
     * @todo investigate using pipeline resolver to determine cognito ID companies
     * @see https://github.com/aws-amplify/amplify-js/issues/3326
     */
    'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
    /**
     * Specify the "requested company" resource
     */
    'x-company-id': store.getState()['_user'].impersonate && store.getState()['_user'].impersonate.companyId
  })
})

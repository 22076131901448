import React from 'react'

const SensyLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.394" height="23.705" viewBox="0 0 23.394 23.705">
      <g id="sensy_svg_line" data-name="sensy svg line" transform="translate(-238.682 -91.122)">
        <path id="Path_107" data-name="Path 107" fill="none" stroke="currentColor" d="M11.2,0a11.276,11.276,0,0,1,11.2,11.352A11.276,11.276,0,0,1,11.2,22.7,11.276,11.276,0,0,1,0,11.352,11.276,11.276,0,0,1,11.2,0Z" transform="translate(239.182 91.622)"/>
        <path id="Path_290" data-name="Path 290" fill="currentColor" d="M0,0,5.16,0l0,5.16L0,5.16Z" transform="translate(249.614 99.416) rotate(28)"/>
        <ellipse id="Ellipse_24" data-name="Ellipse 24" fill="currentColor" cx="1.289" cy="1.291" rx="1.289" ry="1.291" transform="translate(252.889 96.752) rotate(-17)"/>
        <ellipse id="Ellipse_24-2" data-name="Ellipse 24" fill="currentColor" cx="1.289" cy="1.291" rx="1.289" ry="1.291" transform="translate(242.626 99.982) rotate(-17)"/>
        <path id="Path_109" data-name="Path 109" fill="none" stroke="currentColor" d="M0,0A21.709,21.709,0,0,0,6.86,1.33,22.355,22.355,0,0,0,13.785,0" transform="translate(245.468 109.428) rotate(-17)"/>
        <path id="Path_109-2" data-name="Path 109" fill="none" stroke="currentColor" d="M0,0A16.884,16.884,0,0,0,4.749.8,18.066,18.066,0,0,0,9.6,0" transform="translate(248.074 110.731) rotate(-17)"/>
      </g>
    </svg>
  )
}

export default SensyLogo
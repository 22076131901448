import { ACTIONS } from './../actions/assets'

const initial = {
  assets: [],
  loading: false,
  filter: null,
  searchAll: false
}

export default (state = initial, action) => {
  switch (action.type) {
    case ACTIONS.LOAD:
      return { ...state, assets: action.payload }
    case ACTIONS.LOADING:
      return { ...state, loading: Boolean(action.payload) }
    case ACTIONS.FILTER:
      return { ...state, filter: action.payload }
    case ACTIONS.SEARCH_ALL:
      return { ...state, searchAll: action.payload }
    default:
      return state
  }
}
import { ACTIONS } from './../actions/workspace'
import dayjs from 'dayjs'
import { indexer, updateArrayIndex } from './../helpers/'

const initial = {
  selected: { building: 0, floor: 0, area: 0 },
  companyContentKey: dayjs().unix(),
  buildings: [],
  buildingsById: {},
  floors: [],
  floorsById: {},
  areas: [],
  overlay: {
    active: null,
    data: null,
    from: dayjs().subtract('7', 'days'), // Default is for a week
    to: dayjs()
  },
  loading: {},
  geo: null,
  sensorsByDesk: {},
  dataByDesk: {},
  geoDesks: null
}

const reducer = (state = initial, action) => {
  let buildings
  let floors
  let areas

  switch (action.type) {
    case ACTIONS.REMOVE_DEVICE:
      return {
        ...state,
        sensorsByDesk: {
          ...state.sensorsByDesk,
          [action.desk]: (state.sensorsByDesk[action.desk] || []).filter((i) => i.id !== action.id)
        }
      }
    // TODO this should probably move or something, to sensors section
    case ACTIONS.UPDATE_SENSOR:
      // Current desk sensors
      const sensors = (state.sensorsByDesk[action.desk] || []).concat(action.data)
      // Remove duplicates
      const deduplicate = (item, index) => sensors.findIndex((i) => item.id === i.id) === index
      // Return mutated state
      return { ...state, sensorsByDesk: { ...state.sensorsByDesk, [action.desk]: sensors.filter(deduplicate) } }
    case ACTIONS.BUMP_COMPANY_CONTENT_KEY:
      return { ...state, companyContentKey: state.companyContentKey + 1 }
    case ACTIONS.ADD_WORKSPACE_BUILDING:
      return {
        ...state,
        buildings: state.buildings.concat(action.data)
      }
    case ACTIONS.ADD_WORKSPACE_FLOOR:
      return {
        ...state,
        floors: state.floors.concat(action.data)
      }
    case ACTIONS.ADD_WORKSPACE_AREA:
      return {
        ...state,
        areas: state.areas.concat(action.data)
      }
    case ACTIONS.SAVE_WORKSPACE_BUILDING:
      if (action.data.archive) {
        buildings = state.buildings.filter(item => (item.id !== action.data.id))
      } else {
        buildings = updateArrayIndex(state.buildings, state.buildings.findIndex((i) => (i.id === action.data.id)), action.data)
      }

      return {
        ...state,
        buildingsById: indexer(buildings, 'id'),
        buildings
      }
    case ACTIONS.UPDATE_WORKSPACE_FLOOR:
      if (action.data.archive) {
        floors = state.floors.filter(item => (item.id !== action.data.id))
      } else {
        floors = updateArrayIndex(state.floors, state.floors.findIndex((i) => (i.id === action.data.id)), action.data)
      }

      return {
        ...state,
        floorsById: indexer(floors, 'id'),
        floors
      }
    case ACTIONS.UPDATE_WORKSPACE_AREA:
      if (action.data.archive) {
        areas = state.areas.filter(item => (item.id !== action.data.id))
      } else {
        areas = updateArrayIndex(state.areas, state.areas.findIndex((i) => (i.id === action.data.id)), action.data)
      }

      return {
        ...state,
        areasById: indexer(areas, 'id'),
        areas
      }
    case ACTIONS.OVERLAY_GET_DATA:
      return { ...state, overlay: { ...state.overlay, data: action.data } }
    case ACTIONS.OVERLAY_SET_DATE:
      return { ...state, overlay: { ...state.overlay, from: action.from, to: action.to } }
    case ACTIONS.OVERLAY_SET_ACTIVE:
      return { ...state, overlay: { ...state.overlay, active: action.active } }
    /**
     * Set the selected sensor
     */
    case ACTIONS.SELECT_DESK:
      return { ...state, selected: { ...state.selected, desk: action.desk } }
    /**
     * When select a new area
     */
    case ACTIONS.SELECT_AREA:
      return { ...state, selected: { ...state.selected, area: action.area } }
    /**
     * When select a new floor, clear all other selections
     */
    case ACTIONS.SELECT_FLOOR:
      return { ...state, selected: { ...state.selected, floor: action.floor, area: null } }
    /**
     * Selecting a new building should reset all other filters
     */
    case ACTIONS.SELECT_BUILDING:
      return { ...state, selected: { building: action.building, floor: null, area: null } }
    /**
     * Event to make updates to a desk entry
     */
    case ACTIONS.UPDATE_DESK:
      // Define the new desk as a GeoJSON object
      const { geometry, ...props } = action.desk
      const desk = { type: 'Feature', properties: props, geometry }

      // Extract current features, without the edited desk
      const oldFeatures = state.geoDesks.features.filter((i) => (!props.id || i.properties.id !== props.id))

      // Append the desk to our geoDesks object until we refresh the whole object
      return { ...state, geoDesks: { type: 'FeatureCollection', features: [ ...oldFeatures, desk ] } }
    /**
     * Load workspace features. Buildings, floors and areas
     */
    case ACTIONS.LOAD_WORKSPACES:
      return {
        ...state,
        buildings: action.buildings,
        buildingsById: indexer(action.buildings, 'id'),
        floors: action.floors,
        floorsById: indexer(action.floors, 'id'),
        areas: action.areas,
        selected: {
          ...state.selected,
          building: (state.selected.building || (action.buildings[0] && action.buildings[0].id))
        }
      }

    /**
     * Load the active buildings GEO JSON
     */
    case ACTIONS.LOAD_GEO:
      return { ...state, geoDesks: action.geoDesks }
    /**
     * Set loading state for different operations
     */
    case ACTIONS.LOADING:
      return { ...state, loading: { ...state.loading, ...action } }
    /**
     * Load sensors and index by desk
     */
    case ACTIONS.LOAD_SENSOR_DATA:
      return { ...state, sensorsByDesk: { ...state.sensorsByDesk, [action.desk]: action.sensors }, dataByDesk: { ...state.dataByDesk, [action.desk]: action.data } }

    case ACTIONS.LOAD_SENSORS:
      // Index by sensor ID for easy lookup
      const index = action.payload.reduce((accum, current) => {
        accum[current.id] = current
        return accum
      }, {})

      return { ...state, sensors: { ...state.sensors, [action.id]: action.payload }, sensorsById: { ...state.sensorsById, ...index } }
    case ACTIONS.LOADING_SENSOR_DATA:
      return { ...state, loadingSensorData: Boolean(action.payload) }
    default:
      return state
  }
}

export default reducer